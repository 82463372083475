import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import RolesService from './RolesService';
import DirectoryService from '../directory/DirectoryService';
import schema from './jsonSchema';
import { useNavigate } from 'react-router-dom';
import SalaryLevelService from './salarylevels/SalaryLevelService';

const jsonSchema = schema;
const transformDate = inputDateString => {
  // Split the input string based on "/"
  const parts = inputDateString.split('/');

  // Rearrange the parts in the desired order
  const transformedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return transformedDateString;
};
const assignValueToJsonSchema = (
  field,
  users,
  salaryLevels,
  contractTypes,
  statusOptions
) => {
  if (field.name === 'assigned') {
    return {
      ...field,
      options: users
    };
  }
  if (field.name === 'salaryLevel') {
    return {
      ...field,
      options: salaryLevels
    };
  }
  if (field.name === 'status') {
    return {
      ...field,
      options: statusOptions
    };
  }
  if (field.name === 'contractType') {
    return {
      ...field,
      options: contractTypes
    };
  }
  return field;
};
const mergedJsonSchema = (
  jsonSchema,
  users,
  salaryLevels,
  contractTypes,
  statusOptions
) => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f =>
          assignValueToJsonSchema(
            f,
            users,
            salaryLevels,
            contractTypes,
            statusOptions
          )
        )
      };
    } else {
      return assignValueToJsonSchema(
        field,
        users,
        salaryLevels,
        contractTypes,
        statusOptions
      );
    }
  });
};

const RolePositionForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [salaryLevels, setSalaryLevels] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  useEffect(async () => {
    if (undefined !== identifier) {
      RolesService.retrieveRolePosition(identifier)
        .then(data => {
          console.log(data);
          const assigned = [
            {
              label: data.assigned.firstName + ' ' + data.assigned.lastName,
              value: data.assigned.identifier
            }
          ];
          const salaryLevel = [
            { label: data.salaryLevel, value: data.salaryLevel }
          ];
          const contractType = [
            { label: data.contractType, value: data.contractType }
          ];
          const status = [{ label: data.status, value: data.status }];
          delete data.requestedBy;
          const updatedData = {
            ...data,
            assigned: assigned,
            salaryLevel: salaryLevel,
            contractType: contractType,
            startDate: transformDate(data.startDate),
            status: status
          };
          setData(updatedData);
        })
        .catch(error => console.log(error));
    }
    /*const handleChange = ({ target }) => {
      let name = target.name;
      let value = target.value;
      setFormData({ ...formData, [name]: value });
    };*/
    const usersByAccount = await DirectoryService.retrieveUsersByAccount();
    const mappedUsers = usersByAccount.map(u => ({
      value: u.identifier,
      label: u.firstName + ' ' + u.lastName
    }));
    setUsers(mappedUsers);
    const salaryLevels = [
      {
        label: 'No Salary Level Selected',
        value: 'No Salary Level Selected'
      }
    ];
    const roleStatusOptions = [
      {
        label: 'REQUESTED',
        value: 'REQUESTED'
      },
      {
        label: 'APPROVED',
        value: 'APPROVED'
      },
      {
        label: 'REJECTED',
        value: 'REJECTED'
      },
      {
        label: 'ABANDONED',
        value: 'ABANDONED'
      }
    ];
    setStatusOptions(roleStatusOptions);
    setSalaryLevels(salaryLevels);
    SalaryLevelService.retrieveAllSalaryLevelsByAccount().then(data => {
      const updatedSalaryLevels = data.map(d => ({
        label: d.name,
        value: d.name
      }));
      setSalaryLevels(salaryLevels.concat(updatedSalaryLevels));
    });

    const contractTypes = [
      {
        label: 'Permanent Contract',
        value: 'Permanent Contract'
      },
      {
        label: 'Fixed-Term Contract',
        value: 'Fixed-Term Contract'
      },
      {
        label: 'Temporary Contract',
        value: 'Temporary Contract'
      },
      {
        label: 'Freelance/Consultant Contract',
        value: 'Freelance/Consultant Contract'
      },
      {
        label: 'Zero-Hours Contract',
        value: 'Zero-Hours Contract'
      },
      {
        label: 'Apprenticeship Contract',
        value: 'Apprenticeship Contract'
      },
      {
        label: 'Internship Contract',
        value: 'Internship Contract'
      },
      {
        label: 'Agency Worker Contract',
        value: 'Agency Worker Contract'
      },
      {
        label: 'Casual Worker Contract',
        value: 'Casual Worker Contract'
      },
      {
        label: 'Seasonal Contract',
        value: 'Seasonal Contract'
      },
      {
        label: 'Part-Time Contract',
        value: 'Part-Time Contract'
      },
      {
        label: 'Full-Time Contract',
        value: 'Full-Time Contract'
      },
      {
        label: 'Self-Employed/Contractor',
        value: 'Self-Employed/Contractor'
      },
      {
        label: 'Volunteer Position',
        value: 'Volunteer Position'
      }
    ];

    setContractTypes(contractTypes);
  }, []);
  const handleRolePositionFormSubmit = formData => {
    console.log(formData);
    RolesService.saveRolePosition(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Job Vacancy',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Save Job Vacancy');
      }
    },
    {
      name: 'Save Draft',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Save Draft');
      }
    },
    {
      name: 'Manage Salary Levels',
      type: 'button',
      variant: 'info',
      onClick: () => {
        navigate('/app/roles/salarylevels');
      }
    }
  ];

  const headerGenerated = useHeaderGenerator('Job Vacancy', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Job Vacancy  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleRolePositionFormSubmit}
              users={users}
              salaryLevels={salaryLevels}
              contractTypes={contractTypes}
              statusOptions={statusOptions}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({
  data,
  buttonsConf,
  onSubmit,
  users,
  salaryLevels,
  contractTypes,
  statusOptions
}) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(
      jsonSchema,
      users,
      salaryLevels,
      contractTypes,
      statusOptions
    ),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  contractTypes: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any,
  salaryLevels: PropTypes.any,
  statusOptions: PropTypes.any,
  users: PropTypes.any
};
export default RolePositionForm;
