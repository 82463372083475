const jsonSchema = [
  {
    type: 'group',
    fields: [
      {
        name: 'mailUsername',
        label: 'SMTP Username',
        type: 'text',
        showInList: true,
        required: true
      },
      {
        name: 'mailPassword',
        label: 'SMTP Password',
        type: 'text',
        required: true,
        showInList: true
      }
    ]
  },
  {
    type: 'group',
    fields: [
      {
        name: 'mailSmtpHost',
        label: 'mail.smtp.host',
        type: 'text',
        showInList: true,
        required: true
      },
      {
        name: 'mailSmtpPort',
        label: 'mail.smtp.port',
        type: 'text',
        required: true,
        showInList: true
      }
    ]
  },
  {
    type: 'group',
    fields: [
      {
        name: 'mailSmtpAuth',
        label: 'mail.smtp.auth',
        type: 'checkbox',
        showInList: true,
        required: true
      },
      {
        name: 'mailSmtpStarttlsEnable',
        label: 'mail.smtp.starttls.enable',
        type: 'checkbox'
      },
      {
        name: 'mailSmtpSslEnable',
        label: 'mail.smtp.ssl.enable',
        type: 'checkbox',
        showInList: true,
        required: true
      }
    ]
  },
  {
    name: 'linkeinToken',
    label: 'LinkeIn Token',
    type: 'text',
    showInList: true,
    required: false
  }
];

export default jsonSchema;
