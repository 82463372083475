import React, { useState, useEffect } from 'react';
import NotificationService from './NotificationService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import { Link } from 'react-router-dom';
import useTableGenerator from '../common/functions/useTableGenerator';

const NotificationList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [tableGenerated, setTableGenerated] = useState();
  const [refreshList, setRefreshList] = useState(false);

  const convertTime = time => {
    const date = new Date(time);
    return date.toLocaleDateString();
  };
  const deleteNotification = identification => {
    NotificationService.deleteNotification(identification)
      .then(response => {
        console.log(response);
      })
      .catch(error => console.log(error));
  };
  const transformData = (attr, item) => {
    if (attr == 'owner') {
      const url = '/social/directory/user-details/' + item['ownerIdentifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    if (attr === 'time') {
      return convertTime(item[attr]);
    }
    return item[attr];
  };
  const jsonSchema = schema;
  useEffect(() => {
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    NotificationService.retrieveAllNotificationByUser()
      .then(data => {
        const updatedData = data.map(n => {
          const owner = n.owner.firstName + ' ' + n.owner.lastName;
          const time = convertTime(n.time);
          return { ...n, owner: owner, time: time };
        });

        const actionButtons = [
          /* {
            icon: 'edit',
            onClick: (identifier) => console.log(identifier),
            className: 'p-0 me-2',
            title: 'Edit',
            variant: 'action'
          },*/
          {
            icon: 'trash-alt',
            onClick: identifier => {
              deleteNotification(identifier), setRefreshList(true);
            },
            className: 'p-0',
            title: 'Delete',
            variant: 'action'
          }
        ];

        setData(
          filterDataForList(
            updatedData,
            columns.map(c => c.accessor),
            transformData
          )
        );
        setNoDataToShow(!updatedData.length > 0);
        setTableGenerated(
          useTableGenerator(updatedData, jsonSchema, actionButtons)
        );
        setRefreshList(false);
      })
      .catch(error => setError({ error: error.message }));
  }, [refreshList]);

  const buttonsConf = [];
  const headerGenerated = useHeaderGenerator('Notifications', ' ', buttonsConf);
  return (
    <>
      {error}
      {headerGenerated}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Notification to show.
        </Alert>
      )}
      {data && data.length > 0 && tableGenerated}
    </>
  );
};

export default NotificationList;
