import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DirectoryService from '../directory/DirectoryService';
import FormModal from '../common/FormModal';
import Select from 'react-select';

const SingleFile = ({ setRefreshDocuments }) => {
  // 5MB
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 5 * 1024 * 1024
  });
  const [modalShow, setModalShow] = useState(false);
  const [sharedWith, setSharedWith] = useState([]);
  const [users, setUsers] = useState([]);

  const validated = () => {
    return true;
  };
  console.log(sharedWith);
  useEffect(async () => {
    try {
      const usersByAccount = await DirectoryService.retrieveUsersByAccount();
      const mappedUsers = usersByAccount.map(u => ({
        value: u.identifier,
        label: u.firstName + ' ' + u.lastName
      }));
      setUsers(mappedUsers);
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);
  const handleSubmit = event => {
    event.preventDefault();
    const data = JSON.stringify({
      document: null,
      sharedWith: sharedWith
    });
    const sharedFile = acceptedFiles[0];
    const { identifier } = DirectoryService.retrieveLoggedProfile();
    const url = '/api/document/upload/profile/' + identifier;
    const formData = new FormData();
    formData.append('file', sharedFile);
    formData.append('fileName', sharedFile.name);
    formData.append('documentPayload', data);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    axios.post(url, formData, config).then(response => {
      console.log(response.data);
      setModalShow(true);
      setRefreshDocuments(true);
      cleanForm();
    });
  };
  const cleanForm = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
    setSharedWith([]);
  };

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Form
        className="mb-3"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
          <input {...getInputProps({ multiple: false })} name="file" />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your file here</p>
          </Flex>
        </div>
        <div className="mt-3">
          {acceptedFiles.length > 0 && (
            <>
              <h6>File</h6>
              <ul>{files}</ul>
            </>
          )}
        </div>
        <Form.Group className="mb-3" controlId="sharedWith">
          <Form.Label className="fs-0">Shared with</Form.Label>
          <Select
            closeMenuOnSelect={false}
            name="sharedWith"
            key="sharedWith"
            options={users}
            placeholder="Share document with..."
            isMulti={true}
            classNamePrefix="react-select"
            value={sharedWith}
            onChange={value => setSharedWith(value)}
          />
        </Form.Group>
        <Button type="submit" disabled={acceptedFiles.length === 0}>
          Submit File
        </Button>
      </Form>
      {modalShow && (
        <FormModal
          message={'File has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
    </>
  );
};

SingleFile.propTypes = {
  sharedWith: PropTypes.any,
  setRefreshDocuments: PropTypes.func
};
export default SingleFile;
