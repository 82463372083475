const NotificationService = {
  async saveNotification(formData) {
    try {
      let url = '/api/notification/' + formData.identifier;
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { ownerIdentifier } = profileLogged.identifier;
        formData = { ...formData, ownerIdentifier: ownerIdentifier };
        url = '/api/notification';
        method = 'POST';
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newNotification = await response.json();
        console.log('Notification has been updated:', newNotification);
      }
    } catch (error) {
      console.log('Error saving Notification', error);
    }
  },
  async retrieveAllNotificationByUser() {
    try {
      const { identifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(identifier);
      const response = await fetch('/api/notification/profile/' + identifier);
      const data = await response.json();

      // Add new attributes to each item in the array
      const updatedResponseData = data.map(item => ({
        ...item,
        id: item.identifier,
        children: item.message,
        emoji: '💬',
        className: 'rounded-0 border-x-0 border-300 border-bottom-0',
        to: '#!',
        avatar: {
          name: item.owner.firstName + ' ' + item.owner.lastName,
          size: '2xl',
          src: item.owner.avatar
        }
      }));
      console.log(updatedResponseData);
      return updatedResponseData;
    } catch (error) {
      console.error('Error fetching Notification', error);
      throw error;
    }
  },

  async retrieveNotification(identifier) {
    try {
      const response = await fetch('/api/notification/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Notification', error);
      throw error;
    }
  },
  async deleteNotification(identifier) {
    try {
      let url = '/api/notification/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newNotification = await response.json();
        console.log('Notification has been deleted:', newNotification);
      }
    } catch (error) {
      console.log('Error deleting Notification', error);
    }
  }
};
export default NotificationService;
