import WizardForm from 'components/wizard/Wizard';
import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
class RegistrationForm extends React.Component {
  render() {
    return (
      <>
        <AuthCardLayout>
          <WizardForm validation={true} />
        </AuthCardLayout>
      </>
    );
  }
}

export default RegistrationForm;
