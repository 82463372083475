import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from 'components/app/common/functions/filterFieldsForList';
import schema from './jsonSchema';
import AdvanceList from '../../../components/common/advance-table/AdvanceTable';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
const EquipmentList = ({ profile }) => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [noDataToShow, setNoDataToShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setNoDataToShow(false);
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);
    setData(profile.equipments);
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Add equipment',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Equipment');
        navigate('/app/equipments/create-form');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(' ', ' ', buttonsConf);
  return (
    <>
      {headerGenerated}

      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Equipment to show. Let's create a new one!
        </Alert>
      )}
      {data && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

EquipmentList.propTypes = {
  profile: PropTypes.shape({
    equipments: PropTypes.any
  })
};

export default EquipmentList;
