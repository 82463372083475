import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Custom Hook for generating details
function useDetailsGenerator(data, jsonSchema) {
  if (undefined === data) {
    throw new Error('data is undefined');
  }
  return jsonSchema.forEach(field => {
    if (field.type === 'group') {
      return field.fields.forEach(f => {
        console.log(data[f.label]);
        return (
          <Row className="g-2" key={f.name}>
            <Col md>
              <Col.Label>{f.label + ':'}</Col.Label>
              {data[f.name]}
            </Col>
          </Row>
        );
      });
    } else {
      return (
        <Row className="g-2" key={field.name}>
          <Col md>
            <Col.Label>{field.label + ':'}</Col.Label>
            {data[field.name]}
          </Col>
        </Row>
      );
    }
  });
}

export default useDetailsGenerator;
