import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import LoginService from './LoginService';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
  useEffect(() => {
    LoginService.logout()
      .then(() => {
        console.log(layout);
        setShowLogoutContent(true);
      })
      .catch(error => {
        console.error('Logout failed:', error);
      });
  }, []);
  const [showLogoutContent, setShowLogoutContent] = useState(false);
  return (
    <>
      {showLogoutContent && (
        <>
          <img
            className="d-block mx-auto mb-4"
            src={logoutImg}
            alt="shield"
            width={100}
          />
          <TitleTag>See you again!</TitleTag>
          <p>
            Thanks for using BrightStaff. You are{' '}
            <br className="d-none d-sm-block" />
            now successfully signed out.
          </p>
          <Button href="/" color="primary" size="sm" className="mt-3">
            <FontAwesomeIcon
              icon="chevron-left"
              transform="shrink-4 down-1"
              className="me-1"
            />
            Return to Login
          </Button>
        </>
      )}
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
