export default [
  {
    date: '07/02/2020',
    ref: 'February 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£0'
  },
  {
    date: '01/03/2020',
    ref: 'March 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£0'
  },
  {
    date: '01/04/2020',
    ref: 'April 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£0'
  },
  {
    date: '01/05/2020',
    ref: 'May 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£0'
  },
  {
    date: '01/06/2020',
    ref: 'June 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£0'
  },
  {
    date: '01/07/2020',
    ref: 'July 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£852.62'
  },
  {
    date: '01/08/2020',
    ref: 'August 2020',
    employee: 'Mariano Juri',
    amount: '£852.62',
    outstanding: '£852.62'
  }
];
