const PerformanceReviewService = {
  async savePerformanceReview(formData) {
    try {
      let url = '/api/performance';
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { accountIdentifier } = profileLogged;
        console.log(profileLogged);
        console.log(accountIdentifier);
        url = '/api/performance';
        method = 'POST';
      } else {
        url += '/' + formData.identifier;
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newPerformanceReview = await response.json();
        console.log(
          'PerformanceReview has been updated:',
          newPerformanceReview
        );
      }
    } catch (error) {
      console.log('Error saving PerformanceReview', error);
    }
  },
  async retrieveAllPerformanceReviewByProfile() {
    try {
      const { identifier } = JSON.parse(localStorage.getItem('profile'));

      const response = await fetch('/api/performance/profile/' + identifier);
      const data = await response.json();
      const mappedData = data.map(review => ({
        identifier: review.identifier,
        employee: review.employee.firstName + ' ' + review.employee.lastName,
        reviewer: review.reviewer.firstName + ' ' + review.reviewer.lastName,
        reviewDate: review.reviewDate
      }));
      return mappedData;
    } catch (error) {
      console.error('Error fetching PerformanceReview', error);
      throw error;
    }
  },
  async retrieveAllPerformanceReviewByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/<your_controller>' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching PerformanceReview', error);
      throw error;
    }
  },
  async retrievePerformanceReview(identifier) {
    try {
      const response = await fetch('/api/performance/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching PerformanceReview', error);
      throw error;
    }
  },
  async deletePerformanceReview(identifier) {
    try {
      let url = '/api/performance/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newPerformanceReview = await response.json();
        console.log(
          'PerformanceReview has been deleted:',
          newPerformanceReview
        );
      }
    } catch (error) {
      console.log('Error deleting PerformanceReview', error);
    }
  }
};
export default PerformanceReviewService;
