import React, { useState, useEffect } from 'react';
import DocumentService from './DocumentService';
import PropTypes from 'prop-types';
import ActionButton from 'components/common/ActionButton';
import { Table } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import DirectoryService from '../directory/DirectoryService';
import FormModal from '../common/FormModal';
const DocumentList = ({ setRefreshDocuments, refreshDocuments }) => {
  const [documents, setDocuments] = useState([]);
  const [noDocumentsToShow, setNoDocumentsToShow] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const archiveDocument = (identifier, profileIdentifier) => {
    const profileLogged = DirectoryService.retrieveLoggedProfile();
    if (profileLogged.identifier === profileIdentifier) {
      //setShowErrorModal(false);
      DocumentService.deleteDocument(identifier, profileIdentifier)
        .then(doc => {
          console.log(doc);
          setRefreshDocuments(true);
        })
        .catch(error => console.error(error));
    } else {
      setShowErrorModal(true);
    }
  };
  useEffect(() => {
    DocumentService.retrieveDocumentsByProfile()
      .then(docs => {
        console.log(docs);
        return docs.map(d => ({
          name: d.name,
          identifier: d.identifier,
          email: d.profile.email,
          user: d.profile.firstName + ' ' + d.profile.lastName,
          profileIdentifier: d.profile.identifier,
          dateCreated: d.dateCreated,
          url: d.img
        }));
      })
      .then(docs => {
        setDocuments(docs);
        setRefreshDocuments(false);
        setNoDocumentsToShow(!docs.length > 0);
      })
      .catch(error => console.log(error));
  }, [refreshDocuments]);

  return (
    <>
      {noDocumentsToShow && (
        <Alert key="warning" variant="warning">
          There's not any documents to show. Let's create a new one!
        </Alert>
      )}
      <Table bordered responsive>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">User</th>
            <th scope="col">Date created</th>
            <th className={'text-end'} scope="col">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {documents.map(doc => {
            return (
              <tr key={doc.name}>
                <td>
                  <a href={doc.url}>{doc.name}</a>
                </td>
                <td>{doc.user}</td>
                <td>{doc.dateCreated}</td>
                <td className="text-end">
                  <ActionButton
                    icon="trash-alt"
                    title="Remove"
                    variant="action"
                    className="p-0"
                    onClick={() =>
                      archiveDocument(doc.identifier, doc.profileIdentifier)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showErrorModal && (
        <FormModal
          message={"Sorry, you don't have permissions to delete this document"}
          modalShow={showErrorModal}
          setModalShow={setShowErrorModal}
        />
      )}
    </>
  );
};
DocumentList.propTypes = {
  refreshDocuments: PropTypes.bool,
  setRefreshDocuments: PropTypes.func
};
export default DocumentList;
