import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import RolesService from './RolesService';
import schema from './jsonSchema';
import RoleStatusModal from './RoleStatusModal';
import DOMPurify from 'dompurify';
import DirectoryService from '../directory/DirectoryService';
import SettingsService from '../directory/SettingsService';
const RolePositionDetails = () => {
  const { identifier } = useParams();

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [rolePosition, setRolePosition] = useState();
  const [isOpenRoleRejectedModal, setIsOpenRoleRejectedModal] = useState(false);
  const [isOpenRoleApprovedModal, setIsOpenRoleApprovedModal] = useState(false);
  const [assigned, setAssigned] = useState();
  const [status, setStatus] = useState();
  const [headerGenerated, setHeaderGenerated] = useState();

  const sanitizeAndRenderHTML = htmlString => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    return { __html: sanitizedHTML };
  };

  const showValue = attributeName => {
    const value = rolePosition[attributeName];
    if (attributeName === 'jobDescription') {
      return <div dangerouslySetInnerHTML={sanitizeAndRenderHTML(value)}></div>;
    }
    if (attributeName === 'file') {
      return <a href={value}>{rolePosition['fileName'] || 'Download'}</a>;
    }
    if (attributeName === 'assigned') {
      const name = assigned.firstName + ' ' + assigned.lastName;

      return (
        <a href={'/social/directory/user-details/' + assigned.identifier}>
          {name}
        </a>
      );
    }
    if (attributeName === 'status') {
      return (
        <SoftBadge bg="success" className="me-2">
          {status}
        </SoftBadge>
      );
    }
    return value;
  };
  const retrieveButtons = role => {
    const { identifier } = DirectoryService.retrieveLoggedProfile();
    const roleIdentifier = role.identifier;
    const acceptButton = {
      name: 'Accept',
      type: 'button',
      variant: 'success',
      onClick: () => {
        console.log('Accept this Job Vacancy');
        setIsOpenRoleApprovedModal(true);
      }
    };
    const rejectButton = {
      name: 'Reject',
      type: 'button',
      variant: 'alert',
      onClick: () => {
        console.log('Reject this Vacancy');
        setIsOpenRoleRejectedModal(true);
      }
    };
    const editButton = {
      name: 'Edit Vacancy',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this role');
        navigate('/app/roles/' + roleIdentifier + '/edit');
      }
    };
    const deleteButton = {
      name: 'Delete Role',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Delete this job vacancy');
        RolesService.archiveRolePosition(roleIdentifier)
          .then(response => {
            setStatus(response.status), console.log(response);
          })
          .catch(error => console.log(error));
      }
    };
    const linkedInButton = {
      name: 'Publish on Linkedln',
      type: 'button',
      variant: 'info',
      iconClass: 'fa fa-linkein',
      onClick: async () => {
        console.log('Publish on LinkeIn this role');
        const settings = await SettingsService.retrieveSettings();
        const token = settings.linkeinToken;
        if (token === undefined) {
          alert(
            'You need a Token to post a job on Linkdln, to add one, go to Settings'
          );
          return;
        }
        RolesService.publishOnLinkeIn(token, roleIdentifier)
          .then(response => {
            setStatus(response.status), console.log(response);
          })
          .catch(error => console.log(error));
      }
    };
    const assignedIdentifier =
      role.assignedIdentifier !== undefined
        ? role.assignedIdentifier
        : role.assigned.identifier;
    // const requestorIdentifier = role.requestorIdentifier !== undefined ? role.requestorIdentifier : role.requestord.identifier;

    const buttonsConf = [];
    if (assignedIdentifier === identifier && role.status !== 'APPROVED') {
      buttonsConf.push(acceptButton);
    }
    if (assignedIdentifier === identifier && role.status !== 'REJECTED') {
      buttonsConf.push(rejectButton);
    }
    if (
      assignedIdentifier === identifier ||
      role.requestorIdentifier === identifier
    ) {
      buttonsConf.push(editButton);
    }
    if (role.requestorIdentifier === identifier) {
      buttonsConf.push(deleteButton);
    }
    if (DirectoryService.isLoggedByHRAdmin()) {
      buttonsConf.push(linkedInButton);
    }
    return buttonsConf;
  };
  useEffect(() => {
    RolesService.retrieveRolePosition(identifier)
      .then(data => {
        setAssigned(data.assigned);
        setStatus(data.status);

        delete data.assigned;
        console.log(data);
        setRolePosition(data);
        setHeaderGenerated(
          useHeaderGenerator('Job Vacancy', ' ', retrieveButtons(data))
        );
      })
      .catch(error => {
        console.error('Error retrieving Job Vacancy', error);
      });
  }, []);

  const jsonSchema = schema;
  //const headerGenerated = useHeaderGenerator('Job Vacancy', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Job Vacancy has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {rolePosition && (
        <>
          <Card>
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col>{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
              <Row key={'log'}>
                <Col xs={5} sm={4}>
                  <p className="fw-semi-bold mb-1">{'Log' + ': '}</p>
                </Col>
                <Col>{showValue('log')} </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
      {isOpenRoleRejectedModal && (
        <RoleStatusModal
          isOpenRoleApprovedModal={isOpenRoleRejectedModal}
          setOpenRoleApprovedModal={setIsOpenRoleRejectedModal}
          setStatus={setStatus}
          newStatus={'REJECTED'}
          identifier={identifier}
        />
      )}
      {isOpenRoleApprovedModal && (
        <RoleStatusModal
          isOpenRoleApprovedModal={isOpenRoleApprovedModal}
          setOpenRoleApprovedModal={setIsOpenRoleApprovedModal}
          setStatus={setStatus}
          newStatus={'APPROVED'}
          identifier={identifier}
        />
      )}
    </>
  );
};

export default RolePositionDetails;
