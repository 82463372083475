import people from 'data/people';

const transformDateWithFormat = (britDate, formatStyle) => {
  const dateString = britDate;
  const date = new Date(dateString);
  console.log(formatStyle);
  //const formattedDate = format(date, formatStyle);
  //return formattedDate;
  return date;
};
const createDemoHoliday = (profile, start, end) => {
  const title =
    'Holiday requested by ' + profile.firstName + ' ' + profile.lastName;
  const holiday = {
    identifier: 1,
    id: 1,
    title: title,
    authorizedBy: profile.identifier,
    comments: 'Add coments here',
    profile: profile,
    halfDayEnd: false,
    halfDayStart: false,
    includeSaturday: false,
    includeSunday: false,
    dateCreated: createMockDate(start - 7),
    profileIdentifier: profile.identifier,
    type: 'Annual Leave',
    status: 'REQUESTED',
    start: createMockDate(start),
    end: createMockDate(start + end)
  };
  return holiday;
};

const createDemoEvent = (profile, start, end) => {
  console.log(start);
  console.log(end);
  const managerColour = 'primary';
  const childrenColour = 'secondary';
  //const title =
  //  'Event created by ' + profile.firstName + ' ' + profile.lastName;
  const authorizedBy = JSON.parse(localStorage.getItem('profile'));
  const h = profile;
  const event = {
    status: '',
    profile: profile,
    identifier: profile.identifier,
    authorizedBy: authorizedBy.identifier,
    attendees: [profile, authorizedBy],
    url: 'https://demo.coderdsden.uk',
    id: profile.id,
    start: new Date(),
    end: new Date()
  };
  const eventToShow = {
    extendedProps: {
      status: event.status,
      profile: event.profile,
      identifier: event.identifier,
      authorizedBy: event.authorizedBy,
      profileIdentifier: event.profileIdentifier,
      attendees: event.attendees,
      url: event.url,
      editButtons:
        event.authorizedBy === null || event.authorizedBy === profile.identifier
    },
    id: event.identifier,
    title: event.title,
    start: transformDateWithFormat(h.start, 'yyyy-MM-dd'),
    startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
    end: transformDateWithFormat(h.end, 'yyyy-MM-dd'),
    endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),

    classNames: `border border-2 border-primary bg-100`,
    display: 'background',
    color:
      event.authorizedBy === profile.identifier ? childrenColour : managerColour
  };
  return eventToShow;
};
const createMockDate = days => {
  // Get the current date
  const today = new Date();

  // Add 3 days to the current date
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + days);

  // Get the year, month, and day of the future date
  const year = futureDate.getFullYear();
  // Add 1 to month because months are zero-indexed
  const month = String(futureDate.getMonth() + 1).padStart(2, '0');
  const day = String(futureDate.getDate()).padStart(2, '0');

  // Construct the string in the desired format
  const futureDateString = `${year}-${month}-${day}T00:00:00`;

  return futureDateString;
};
const DemoService = {
  switchDemoMode() {
    console.log(1);
  },
  isDemoModeActive() {
    return localStorage.getItem('bstDemoMode') === 'true';
  },
  isAValidIdentifier(identifier) {
    // For a new one
    if (identifier === null) {
      return true;
    }
    const strValue = String(identifier);
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    console.log(identifier);
    console.log(uuidRegex.test(strValue));
    return uuidRegex.test(strValue);
  },
  retrieveDemoUsers() {
    const demoUsers = people.slice(0, 15);
    const updatedDemoUsers = demoUsers.map(u => ({
      ...u,
      identifier: u.id,
      email:
        u.name.split(' ')[0].toLocaleLowerCase() +
        '.' +
        u.name.split(' ')[1].toLowerCase() +
        '@demo.uk',
      avatar: u.avatarSrc,
      lastName: u.name.split(' ')[1],
      firstName: u.name.split(' ')[0],
      roles: [{ key: 'HR User' }],
      personalMobile: '07761318934',
      employeeNumber: u.id,
      contract: {
        jobType: 'Demo Account',
        location: 'London Office',
        country: 'United Kingdom'
      }
    }));
    return updatedDemoUsers;
  },
  retrieveDemoAnnoucements() {
    const profile = JSON.parse(localStorage.getItem('profile'));
    const body =
      "Welcome to Bright Staff! We're honored to have you aboard as we embark on a journey of optimized HR management together.";
    const updatedProfile = { ...profile, email: 'admin@coderdsen.uk' };
    const annoucement = {
      body: body,
      pingToTheTop: true,
      emailNotification: false,
      audience: [profile],
      profileIdentifier: profile.identifier,
      draft: false,
      profile: updatedProfile,
      topic: 'Annoucement'
    };
    const demoAnnoucements = new Array(annoucement);

    return demoAnnoucements;
  },
  retrieveDemoHolidays() {
    const demoUsers = this.retrieveDemoUsers();

    const h1 = createDemoHoliday(demoUsers[5], 0, 4);
    const h2 = createDemoHoliday(demoUsers[0], 3, 2);
    const h3 = createDemoHoliday(demoUsers[2], 10, 3);
    const h4 = createDemoHoliday(demoUsers[1], 12, 5);
    const demoHolidays = [h1, h2, h3, h4];

    return demoHolidays;
  },
  retrieveDemoEvents() {
    const demoUsers = this.retrieveDemoUsers();

    const h1 = createDemoEvent(demoUsers[5], 0, 4);
    const h2 = createDemoEvent(demoUsers[0], 3, 2);
    const h3 = createDemoEvent(demoUsers[2], 10, 3);
    const demoHolidays = [h1, h2, h3];

    return demoHolidays;
  }
};
export default DemoService;
