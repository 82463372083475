const filterFieldsForList = jsonSchema => {
  // Initialize an empty array to store the filtered fields
  const filteredFields = [];

  // Iterate over each object in the jsonSchema
  jsonSchema.forEach(obj => {
    // Check if the object has a 'fields' property (i.e., it's a 'group' type)
    if (obj.fields) {
      // Filter the 'fields' array to include only those with 'showInList' set to true
      const filteredGroupFields = obj.fields.filter(
        field => field.showInList === true
      );

      // Map the filtered fields to extract 'name' and 'label'
      const mappedFields = filteredGroupFields.map(({ name, label }) => ({
        accessor: name,
        Header: label
      }));

      // Concatenate the mapped fields to the result array
      filteredFields.push(...mappedFields);
    } else if (obj.showInList === true) {
      // If it's not a 'group' type but has 'showInList' as true, add it directly
      filteredFields.push({ accessor: obj.name, Header: obj.label });
    }
  });
  return filteredFields;
};
export default filterFieldsForList;
