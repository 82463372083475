import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import SupportTicketService from './SupportTicketService';

import schema from './jsonSchema';
const jsonSchema = schema;
const assignValueToJsonSchema = (field, ticketTypes) => {
  if (field.name === 'type') {
    return {
      ...field,
      options: ticketTypes
    };
  }
  return field;
};
const mergedJsonSchema = (jsonSchema, ticketTypes) => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f => assignValueToJsonSchema(f, ticketTypes))
      };
    } else {
      return assignValueToJsonSchema(field, ticketTypes);
    }
  });
};
const SupportTicketForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [ticketTypes, setTicketTypes] = useState();

  useEffect(() => {
    const ticketTypes = [
      { label: 'Technical Support', value: 'Technical Support' },
      { label: 'Sales', value: 'Sales' }
    ];
    setTicketTypes(ticketTypes);
    if (undefined !== identifier) {
      SupportTicketService.retrieveSupportTicket(identifier)
        .then(data => {
          const updatedData = {
            ...data,
            type: { value: data.type, label: data.type }
          };
          setData(updatedData);
        })
        .catch(error => console.log(error));
    }
  }, []);
  const handleSupportTicketFormSubmit = formData => {
    SupportTicketService.saveSupportTicket(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save SupportTicket',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save SupportTicket');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Create SupportTicket',
    ' ',
    buttonsConf
  );

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'SupportTicket  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleSupportTicketFormSubmit}
              types={ticketTypes}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit, types }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, types),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any,
  types: PropTypes.any
};
export default SupportTicketForm;
