const jsonSchema = [
  {
    name: 'employee',
    label: 'Employee',
    required: true,
    showInList: true,
    type: 'multiselect',
    isMulti: false
  },
  {
    name: 'reviewer',
    label: 'Reviewer',
    type: 'multiselect',
    showInList: true,
    isMulti: false,
    required: true
  },
  {
    name: 'goals',
    label: 'Goals',
    type: 'multiselect',
    isMulti: true,
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'reviewDate',
        label: 'Review Date',
        type: 'date',
        showInList: true
      }
    ]
  },
  {
    name: 'comments',
    label: 'Comments',
    type: 'editor',
    required: true
  }
];
export default jsonSchema;
