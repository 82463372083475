import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import DirectoryService from './DirectoryService';
import { Link } from 'react-router-dom';
const UserList = () => {
  const [data, setData] = useState();
  const [users, setUsers] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [exportToExcelData, setExportToExcelData] = useState();

  const refreshData = data => {
    console.log(data);
    DirectoryService.retrieveUsersByAccount()
      .then(data => {
        setUsers(data);
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformUserDataFunction
          )
        );
        setNoDataToShow(!data.length > 0);
        const exportToExcelData = data.map(x => ({
          identifier: x.identifier,
          email: x.email
        }));
        setExportToExcelData(exportToExcelData);
      })
      .catch(error => setError({ error: error.message }));
  };
  const bulkActions = (selectedRows, action) => {
    console.log(selectedRows);
    console.log(action);
    Object.keys(selectedRows).forEach(element => {
      const identifier = users[element].identifier;
      console.log(identifier);
      if ('delete' === action) {
        DirectoryService.deleteUserProfile(identifier)
          .then(value => {
            console.log(value);
            refreshData(data);
          })
          .catch(error => console.log(error));
      }
    });
  };
  const transformUserDataFunction = (attr, item) => {
    if (attr == 'email') {
      const url = '/social/directory/user-details/' + item['identifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    return item[attr];
  };
  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);
    console.log(exportToExcelData);
    DirectoryService.retrieveUsersByAccount()
      .then(data => {
        setUsers(data);
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformUserDataFunction
          )
        );
        setNoDataToShow(!data.length > 0);
        const exportToExcelData = data.map(x => ({
          identifier: x.identifier,
          email: x.email
        }));
        setExportToExcelData(exportToExcelData);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);
  return (
    <>
      {error}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any User to show. Let's create a new one!
        </Alert>
      )}
      {data && (
        <AdvanceList
          columns={columns}
          data={data}
          selection={true}
          searchBox={true}
          bulkActions={true}
          bulkActionsFx={bulkActions}
        />
      )}
    </>
  );
};

export default UserList;
