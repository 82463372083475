import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import coverSrc from 'assets/img/generic/4.jpg';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileBanner from 'components/pages/user/ProfileBanner';
import VerifiedBadge from 'components/common/VerifiedBadge';

const Banner = ({ profile }) => {
  console.log(profile);
  const avatar = profile !== null ? profile.avatar : null;
  const jobType =
    profile !== null &&
    profile.contract !== null &&
    profile.contract.jobType !== null
      ? profile.contract.jobType
      : "Don't forget to complete your job details!";
  const location =
    profile !== null &&
    profile.contract !== null &&
    profile.contract.location !== null
      ? profile.contract.location + ', ' + profile.contract.country
      : '';
  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar || null} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {profile.firstName !== null
                ? profile.firstName + ' ' + profile.lastName
                : 'Complete the form'}{' '}
              <VerifiedBadge />
            </h4>
            <h5 className="fs-0 fw-normal">{jobType}</h5>
            <p className="text-500">{location}</p>

            <div className="border-dashed-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            <Link to="#!">
              <Flex alignItems="center" className="mb-2">
                <FontAwesomeIcon
                  icon="envelope"
                  className="fs-3 me-2 text-700"
                />
                <div className="flex-1">
                  <h6 className="mb-0">{profile.email}</h6>
                </div>
              </Flex>
            </Link>
            <Link to="#!">
              <Flex alignItems="center" className="mb-2">
                <FontAwesomeIcon icon="mobile" className="fs-3 me-2 text-700" />
                <div className="flex-1">
                  <h6 className="mb-0">{profile.personalMobile}</h6>
                </div>
              </Flex>
            </Link>
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  profile: PropTypes.shape({
    avatar: PropTypes.any,
    contract: PropTypes.shape({
      country: PropTypes.any,
      jobType: PropTypes.any,
      location: PropTypes.string
    }),
    email: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.any,
    personalMobile: PropTypes.any
  })
};

export default Banner;
