import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import TinymceEditor from 'components/common/TinymceEditor';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useCallback } from 'react';

import './styles.css';
// Custom Hook for generating forms
function useFormGenerator(
  jsonSchema,
  handleSubmitCallBack,
  initialValues = {},
  setValidatedCallBack
) {
  const stringToDate = s => {
    return new Date(s);
  };
  // Create state to store form data
  const [formData, setFormData] = useState(initialValues);
  // Handle form field changes
  const handleChange = event => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };
  const isEditable = field => {
    return field.notEditable == undefined || field.notEditable !== true;
  };
  const validateSelectValues = field => {
    if (!isFieldRequired(field)) {
      return true;
    }
    if (
      formData[field.name] ||
      (field.isMulti &&
        formData[field.name] !== undefined &&
        formData[field.name].length > 0)
    ) {
      return true;
    }
    return false;
  };
  // Handle form submission
  const handleSubmit = event => {
    event.preventDefault();
    // event.stopPropagation();
    const form = event.currentTarget;
    console.log(form.checkValidity());
    setValidatedCallBack(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        console.log('Form Data:', formData);
        handleSubmitCallBack(formData);
      } catch (error) {
        console.error('Error saving', error);
      }
    }
  };

  const isFieldRequired = field => {
    console.log(field);
    if (field.required !== undefined && field.required === true) {
      return true;
    }
    return false;
  };

  const generateFormControl = field => {
    if (field.type === 'checkbox') {
      const [isChecked, setIsChecked] = useState(formData[field.name]);
      return (
        <Form.Check
          type="switch"
          id={field.name}
          name={field.name}
          label={field.label}
          checked={isChecked}
          onChange={() => {
            setFormData({ ...formData, [field.name]: !isChecked });
            setIsChecked(!isChecked);
          }}
        ></Form.Check>
      );
    }
    if (field.type === 'fileUploader') {
      const [acceptedFiles, setAcceptedFiles] = useState([]);
      console.log(formData[field.name]);
      console.log(formData);
      const sizeMb = field.maxSize || 5;

      const onDrop = useCallback(acceptedFiles => {
        // Update the state with the accepted files
        setAcceptedFiles(acceptedFiles);
        console.log(formData);
        setFormData(prevFormData => ({
          ...prevFormData,
          [field.name]: acceptedFiles[0]
        }));
        console.log(formData);
        // Now formData contains the accepted files
        console.log(formData);
      }, []);
      const { getRootProps, getInputProps } = useDropzone({
        maxSize: sizeMb * 1024 * 1024,
        onDrop
      });
      const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      ));
      return (
        <Form.Group className="mb-3" controlId={field.name}>
          <Form.Label className="fs-0">{field.label}</Form.Label>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input
              {...getInputProps({ multiple: field.multiple })}
              name={field.name}
            />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop your file here</p>
            </Flex>
          </div>
          <div className="mt-3">
            {acceptedFiles.length > 0 && (
              <>
                <h6>File</h6>
                <ul>{files}</ul>
              </>
            )}
          </div>
        </Form.Group>
      );
    }
    if (field.type === 'daypicker') {
      console.log(formData[field.name]);
      return (
        <Form.Group className="mb-3" controlId={field.name}>
          <Form.Label className="fs--1">{field.label}</Form.Label>
          <DatePicker
            selected={stringToDate(formData[field.name])}
            onChange={date => {
              setFormData({ ...formData, [field.name]: date });
            }}
            required
            className="form-control"
            placeholderText={stringToDate(formData[field.name])}
            dateFormat={field.pattern || 'dd/MM/yyyy h:mm aa'}
            showTimeSelect
            name={field.name}
            value={formData[field.name]}
          />
          <Form.Control.Feedback type="invalid">
            Please a valide start date for the event
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
    if (field.type === 'multiselect') {
      return (
        <Form.Group className="mb-3" controlId={field.name}>
          <Form.Label className="fs--1">{field.label}</Form.Label>
          <Select
            closeMenuOnSelect={false}
            name={field.name}
            key={field.name}
            options={field.options}
            placeholder="Select..."
            required={isFieldRequired(field)}
            isMulti={field.isMulti}
            classNamePrefix="react-select"
            value={formData[field.name] || ''}
            onChange={value => {
              setValidatedCallBack(validateSelectValues(field));
              setFormData({ ...formData, [field.name]: value });
            }}
            className={validateSelectValues(field) ? 'is-valid' : 'is-invalid'}
          />
          <Form.Control.Feedback type="invalid">
            {field.label + ' required'}
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
    if (field.type === 'editor') {
      return (
        <Form.Group className="mb-3" controlId={field.name}>
          <Form.Label className="fs--1">{field.label}</Form.Label>
          <TinymceEditor
            handleChange={value => {
              const customEvent = {
                target: { name: field.name, value: value }
              };
              handleChange(customEvent);
            }}
            name={field.name}
            value={formData[field.name]}
            required={isFieldRequired(field)}
          />
        </Form.Group>
      );
    }
    return (
      <FloatingLabel label={field.label} className="mb-2">
        {console.log(formData)}
        <Form.Control
          size="sm"
          id={field.name}
          name={field.name}
          type={field.type}
          tabIndex={field.tabIndex}
          required={isFieldRequired(field)}
          value={formData[field.name] || ''}
          onChange={handleChange}
        />
      </FloatingLabel>
    );
  };
  // Generate form fields based on JSON schema
  const generateFormFields = () => {
    return jsonSchema.map(field => {
      if (field.type === 'group') {
        return (
          <Row className="g-2">
            {field.fields.map(f => {
              if (isEditable(f)) {
                return (
                  <Col md key={f.name}>
                    {generateFormControl(f)}
                  </Col>
                );
              }
            })}
          </Row>
        );
      } else {
        if (isEditable(field)) {
          return (
            <Row className="g-2">
              <Col md key={field.name}>
                {generateFormControl(field)}
              </Col>
            </Row>
          );
        }
      }
    });
  };

  // Return the form fields, handleSubmit, and handleChange
  return {
    formFields: generateFormFields(),
    handleSubmit,
    handleChange
  };
}

export default useFormGenerator;
