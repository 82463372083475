import React from 'react';
import { Button } from 'react-bootstrap';
import LoginForm from '../../authentication/LoginForm';
import { useState } from 'react';
import AuthCardLayout from 'layouts/AuthCardLayout';
import Alert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';

const BrightStaffLogin = ({ showRegistration }) => {
  const handleSubmit = e => {
    e.preventDefault();
    let method = 'POST';

    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: JSON.stringify({
        userName: e.target.email.value,
        password: e.target.password.value
      })
    };
    let url = '/api/login';
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => {
        localStorage.setItem('token', data.token);
        url = '/api/profile/token/' + data.token;
        return fetch(url)
          .then(response => {
            if (!response.ok) throw new Error(response.status);
            return response.json();
          })
          .then(data => {
            localStorage.setItem('profile', JSON.stringify(data));
            window.location.href = '/';
          })
          .catch(error => {
            throw new Error(error);
          });
      })
      .catch(error => {
        console.log(error);
        setShowError(true);
      });
    //LoginService.login(e.target.email.value, e.target.password.value).then(()=>{window.location.href = "/"}).catch((error) => { setShowError(true) });
  };

  const [showError, setShowError] = useState(false);
  return (
    <>
      <AuthCardLayout
        leftSideContent={
          <p className="text-white">
            Don't have an account?
            <br />
            <Button className="text-white" onClick={showRegistration}>
              Get started!
            </Button>
          </p>
        }
      >
        <h3 className="m-2">Account Login</h3>
        {showError && (
          <Alert key="warning" variant="warning">
            Password is wrong, try again!
          </Alert>
        )}
        <LoginForm layout="card" hasLabel onSubmit={handleSubmit} />
      </AuthCardLayout>
    </>
  );
};
BrightStaffLogin.propTypes = {
  showRegistration: PropTypes.bool
};
export default BrightStaffLogin;
