class ProfileContract {
  constructor() {
    this.lengthOfService = '';
    this.startDate = '';
    this.contService = '';
    this.benefitsStart = '';
    this.jobType = '';
    this.country = '';
    this.location = '';
    this.department = '';
    this.myLineManager = '';
    this.rightToWork = '';
    this.rightToWorkExpires = '';
    this.hoursPerWeek = 0;
    this.daysPerWeek = 0;
    this.fte = 0;
    this.workPattern = '';
    this.holidayEntitlement = 0;
    this.holidayBroughtForward = 0;
    this.holidayTotalThisYear = 0;
    this.noticePeriod = '';
    this.firstName = '';
    this.lastName = '';
  }
}
export default ProfileContract;
