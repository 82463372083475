import React, { createRef } from 'react';

import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import HolidayService from './HolidayService';

import CalendarHolidayModal from './CalendarHolidayModal';
import BookHolidayModal from './BookHolidayModal';
// See https://fullcalendar.io/docs/react

export class HolidaysCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      initialEvents: [],
      isOpenScheduleModal: false,
      scheduleStartDate: new Date(),
      scheduleEndDate: new Date(),
      isOpenEventModal: false,
      modalEventContent: null,
      calendarApi: null,
      calendarRef: createRef(),
      eventSelected: null
    };

    this.setScheduleStartDate = this.setScheduleStartDate.bind(this);
    this.setScheduleEndDate = this.setScheduleEndDate.bind(this);
    this.isOpenScheduleModal = this.isOpenScheduleModal.bind(this);
    this.setIsOpenScheduleModal = this.setIsOpenScheduleModal.bind(this);
    this.setIsOpenEventModal = this.setIsOpenEventModal.bind(this);
    this.setInitialEvents = this.setInitialEvents.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.setModalEventContent = this.setModalEventContent.bind(this);
  }
  setScheduleStartDate(startDate) {
    this.setState({ scheduleStartDate: startDate });
  }
  setScheduleEndDate(endDate) {
    this.setState({ scheduleEndDate: endDate });
  }
  isOpenScheduleModal() {
    return this.state.isOpenScheduleModal;
  }
  setIsOpenScheduleModal(isOpen) {
    this.setState({ isOpenScheduleModal: isOpen });
    return this.state.isOpenScheduleModal;
  }
  setIsOpenEventModal(isOpen) {
    this.setState({ isOpenEventModal: isOpen });
    return this.state.isOpenEventModal;
  }
  setInitialEvents(events) {
    this.setState({ initialEvents: events });
  }
  setModalEventContent(info) {
    this.setState({ modalEventContent: info });
  }

  handleEventClick(info) {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      this.setModalEventContent(info);
      this.setIsOpenEventModal(true);
    }
  }
  componentDidMount() {
    HolidayService.retrieveHolidayRequests().then(abs => {
      this.setState({
        events: abs,
        initialEvents: abs,
        isOpenScheduleModal: false
      });
    });
    this.setState({ calendarApi: this.state.calendarRef.current.getApi() });
  }
  render() {
    return (
      <>
        <IconButton
          variant="primary"
          iconClassName="me-2"
          icon="plus"
          // transform="shrink-3"
          size="sm"
          onClick={() => {
            this.setIsOpenScheduleModal(!this.isOpenScheduleModal());
          }}
        >
          Request Leave
        </IconButton>
        <FullCalendar
          ref={this.state.calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin
          ]}
          initialView="dayGridMonth"
          themeSystem="bootstrap"
          stickyHeaderDates={false}
          editable
          selectable
          selectMirror
          select={info => {
            this.setIsOpenScheduleModal(true);
            this.setScheduleStartDate(info.start);
            this.setScheduleEndDate(info.end);
          }}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          events={this.state.initialEvents}
          eventClick={this.handleEventClick}
        />

        <BookHolidayModal
          isOpenScheduleModal={this.state.isOpenScheduleModal}
          setIsOpenScheduleModal={this.setIsOpenScheduleModal}
          initialEvents={this.state.events}
          setInitialEvents={this.setInitialEvents}
          scheduleStartDate={this.state.scheduleStartDate}
          scheduleEndDate={this.state.scheduleEndDate}
          setScheduleStartDate={this.setScheduleStartDate}
          setScheduleEndDate={this.setScheduleEndDate}
        />
        <CalendarHolidayModal
          isOpenEventModal={this.state.isOpenEventModal}
          setIsOpenEventModal={this.setIsOpenEventModal}
          modalEventContent={this.state.modalEventContent}
        />
      </>
    );
  }
}

export default HolidaysCalendar;
