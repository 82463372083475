const jsonSchema = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    name: 'status',
    label: 'Status',
    type: 'text',
    required: false,
    showInList: true,
    notEditable: true
  },
  {
    name: 'dateCreated',
    label: 'Date',
    type: 'daypicker',
    required: true,
    notEditable: true,
    pattern: 'dd/MM/yyyy h:mm aa',
    showInList: true
  },
  {
    name: 'type',
    label: 'Type',
    type: 'multiselect',
    showInList: true,
    isMulti: false,
    required: true
  },
  {
    name: 'body',
    label: 'Description',
    type: 'editor',
    required: false,
    showInList: false
  }

  // Add more fields as needed
];
export default jsonSchema;
