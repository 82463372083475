import PropTypes from 'prop-types';
import React from 'react';
import createMarkup from 'helpers/createMarkup';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import defaultAvatar from 'assets/img/team/avatar.png';
import DirectoryService from '../directory/DirectoryService';
const AnnoucementItem = ({ identifier, body, profile, topic }) => {
  const className = 'rounded-0 border-x-0 border-300 border-bottom-0';
  const unread = true;
  const flush = false;
  const avatar = {
    status: 'online',
    src:
      profile !== undefined && profile.avatar !== null
        ? profile.avatar
        : defaultAvatar,
    size: '2xl'
  };
  const url = DirectoryService.isLoggedByHRAdmin()
    ? '/app/annoucements/' + identifier + '/edit'
    : '#!';
  const emoji = '📢';
  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': unread, 'notification-flush': flush },
        className
      )}
      to={url}
    >
      {avatar && (
        <div className="notification-avatar">
          <Avatar {...avatar} className="me-3" />
        </div>
      )}
      <div className="notification-body">
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(body)} />
        <span className="notification-time">
          {emoji && (
            <span className="me-2" role="img" aria-label="Emoji">
              {emoji}
            </span>
          )}
          {topic + ' by ' + profile.email}
        </span>
      </div>
    </Link>
  );
};

AnnoucementItem.propTypes = {
  body: PropTypes.any,
  identifier: PropTypes.any,
  profile: PropTypes.shape({
    avatar: PropTypes.any,
    email: PropTypes.any
  }),
  topic: PropTypes.string
};

export default AnnoucementItem;
