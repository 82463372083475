import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import DirectoryService from '../directory/DirectoryService';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
const BookHolidayModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  setInitialEvents,
  initialEvents,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const profileIdentifier = DirectoryService.retrieveProfileIdentifier();
  const manager = DirectoryService.retrieveManagerForThisUser();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const verifySwitchControlsChange = target => {
    let name = target.name;

    if (name === 'halfDayStart') {
      if (target.value == 'on') {
        return true;
      } else {
        return false;
      }
    }
    if (name === 'halfDayEnd') {
      if (target.value == 'on') {
        return true;
      } else {
        return false;
      }
    }
    if (name === 'includeSaturday') {
      if (target.value == 'on') {
        return true;
      } else {
        return false;
      }
    }
    if (name === 'includeSunday') {
      if (target.value == 'on') {
        return true;
      } else {
        return false;
      }
    }
    return target.value;
  };
  const handleChange = ({ target }) => {
    let name = target.name;
    let value = verifySwitchControlsChange(target);
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);
    // Date validation logic
    const startDate = moment(formData.start, 'DD/MM/YYYY');
    const endDate = moment(formData.end, 'DD/MM/YYYY');

    if (endDate.isBefore(startDate)) {
      setError('End date cannot be before start date.');
      return; // Prevent the form from being submitted
    } else {
      setError(''); // Clear any previous error
    }

    const holiday = {
      ...formData,
      profileIdentifier: profileIdentifier,
      authorizedBy: manager?.identifier || null,
      start: new Date(formData.start).getTime(),
      end: new Date(formData.end).getTime()
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(holiday)
    };
    console.log(holiday);
    fetch('/api/holiday/request', requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log(data);
        //this.setState({ followers: data });
      })
      .catch(error => {
        //this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
    const title =
      'Holiday ' +
      transformDate(holiday.start) +
      ' to ' +
      transformDate(holiday.end);
    setInitialEvents([
      ...initialEvents,
      { id: uuid(), title: title, ...formData } // TODO check this
    ]);
    setIsOpenScheduleModal(false);
  };
  const transformDate = britDate => {
    const date = new Date(britDate);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();

    // Format the date as dd/MM/yyyy
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    return formattedDate;
  };
  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData({
        ...formData,
        start: scheduleStartDate,
        end: scheduleEndDate
      });
    } else {
      setScheduleStartDate(null);
      setScheduleEndDate(null);
    }
  }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Request</Modal.Title>
        </Modal.Header>
        {error != '' && (
          <Alert key="warning" variant="warning">
            {error}
          </Alert>
        )}
        <Modal.Body className="p-card">
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Start Date</Form.Label>
            <DatePicker
              selected={scheduleStartDate}
              required
              onChange={date => {
                setScheduleStartDate(date);
                setFormData({ ...formData, start: date });
              }}
              className="form-control"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">End Date</Form.Label>
            <DatePicker
              requited
              selected={scheduleEndDate}
              onChange={date => {
                setScheduleEndDate(date);
                setFormData({ ...formData, end: date });
              }}
              className="form-control"
              placeholderText="DD/MM/YYYY"
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <Form.Group className="mb-2  g-2" controlId="label">
            <Form.Label className="fs-0">Type</Form.Label>
            <Form.Select name="type" onChange={handleChange}>
              <option>None</option>
              <option value="ANNUAL_LEAVE">Annual Leave</option>
              <option value="UNWELL">Unwell</option>
              <option value="BANK_HOLIDAY">Bank Holiday</option>
              <option value="OTHER">Other</option>
            </Form.Select>
          </Form.Group>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} controlId="includeSaturday">
              <Form.Check
                type="switch"
                name="includeSaturday"
                label="Include Saturday"
                onChange={handleChange}
              ></Form.Check>
            </Form.Group>
            <Form.Group as={Col} controlId="includeSunday">
              <Form.Check
                type="switch"
                name="includeSunday"
                label="Include Sunday"
                onChange={handleChange}
              ></Form.Check>
            </Form.Group>
          </Row>
          <Row className="mb-2 g-2">
            <Form.Group as={Col} controlId="halfDayStart">
              <Form.Check
                type="switch"
                name="halfDayStart"
                label="Half Day Start"
                onChange={handleChange}
              ></Form.Check>
            </Form.Group>
            <Form.Group as={Col} controlId="halfDayEnd">
              <Form.Check
                type="switch"
                name="halfDayEnd"
                label="Half Day End"
                onChange={handleChange}
              ></Form.Check>
            </Form.Group>
          </Row>
          {!DirectoryService.isLoggedByHRAdmin() && (
            <Row>
              <Form.Group className="mb-3" controlId="label">
                <Form.Label className="fs-0">Approver</Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={
                    (manager?.firstName || '') + ' ' + (manager?.lastName || '')
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="label">
                <Form.Label className="fs-0">Send to</Form.Label>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={manager?.email || ''}
                />
              </Form.Group>
            </Row>
          )}
          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="comments"
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Link to="#!" className="me-3 text-600">
            More options
          </Link>
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

BookHolidayModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default BookHolidayModal;
