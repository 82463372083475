import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { useState } from 'react';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const OneStepRegistrationForm = ({
  name,
  email,
  phone,
  company,
  numberOfEmployees
}) => {
  const firstName = name !== undefined ? name.split(' ')[0] : name;
  const lastName = name !== undefined ? name.replace(firstName, '') : name;
  const [formData, setFormData] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    company: company || '',
    numberOfEmployees: numberOfEmployees || '',
    email: email || '',
    telephone: phone || ''
  });
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isTnCChecked, setIsTnCChecked] = useState(false);
  const numberOfEmployeesLabels = ['Unlimited', '1-10', '11-50', '51-100'];
  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      console.log(isTnCChecked);
      return;
    }

    if (!isTnCChecked) {
      return;
    }
    setValidated(true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      }
    };

    const {
      firstName,
      lastName,
      email,
      password,
      telephone,
      company,
      numberOfEmployees,
      tnc
    } = formData;

    const payload = JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      telephone,
      companyName: company,
      numberOfEmployees
    });

    const url = '/api/profile/signup';
    try {
      const response = await axios.post(url, payload, config);
      console.log(response);
      console.log(tnc);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <AuthCardLayout>
        <Card>
          <Card.Header>
            <h3>
              Complete your <b>registration</b>.
            </h3>
          </Card.Header>
          <Card.Body>
            <Form
              onSubmit={handleSubmit}
              id="invoice-form"
              noValidate
              validated={validated}
            >
              <Row className="mb-2">
                <Col sm={true}>
                  <FloatingLabel label="First Name">
                    <Form.Control
                      required
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={true}>
                  <FloatingLabel label="Last Name">
                    <Form.Control
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FloatingLabel label="Company">
                    <Form.Control
                      required
                      id="company"
                      name="company"
                      type="text"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FloatingLabel label="Phone">
                    <Form.Control
                      required
                      id="telephone"
                      name="telephone"
                      type="text"
                      value={formData.telephone}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FloatingLabel label="Number of employees">
                    <Form.Select
                      id="numberOfEmployees"
                      name="numberOfEmployees"
                      type="text"
                      value={formData.numberOfEmployees}
                      onChange={handleChange}
                    >
                      {numberOfEmployeesLabels.map((value, index) => (
                        <option key={index} value={value}>
                          {value + ' employees'}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FloatingLabel label="Email">
                    <Form.Control
                      required
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FloatingLabel label="Password">
                    <Form.Control
                      required
                      id="password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <Form.Group controlId="tnc">
                    <Form.Check
                      type="switch"
                      id="tnc"
                      name="tnc"
                      required
                      label="I have read and accept terms and conditions"
                      checked={isTnCChecked}
                      onChange={() => {
                        setFormData({ ...formData, tnc: !isTnCChecked });
                        setIsTnCChecked(!isTnCChecked);
                      }}
                    ></Form.Check>
                    <Form.Control.Feedback type="invalid">
                      {
                        'You must accept terms and conditions to continue required'
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <p>
                  We will use your data to provide you with relevant support,
                  advice and information realted to our service.
                </p>
                <Col></Col>
              </Row>
              <Button className="btn btn-large btn-success" type="submit">
                Get Started
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </AuthCardLayout>
    </>
  );
};
OneStepRegistrationForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  numberOfEmployees: PropTypes.string
};
export default OneStepRegistrationForm;
