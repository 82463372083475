//import singleProfile from '../directory/data/single-profile.json';
const LoginService = {
  isUserAuthenticated: function () {
    return localStorage.getItem('token') ? true : false;
  },
  async logout() {
    let method = 'POST';
    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: localStorage.getItem('token')
    };

    let url = '/api/login/logout';

    return fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(data => {
        console.log(data);
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        return data; // Pass the response data to the next `.then` block
      })
      .catch(error => {
        console.error('There was an error!', error);
        throw error; // Rethrow the error to be caught by the caller
      });
  },
  async login(userName, password) {
    let method = 'POST';

    const requestOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With'
      },
      body: JSON.stringify({
        userName: userName,
        password: password
      })
    };
    let url = '/api/login';
    fetch(url, requestOptions).then(response => {
      if (!response.ok) throw new Error(response.status);
      return response.json();
    });
  }
};

export default LoginService;
