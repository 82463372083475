import PropTypes from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';

class InvoicePaymentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      showPayments: false
    };
  }
  componentDidMount() {
    this.setState({ payments: this.props.paymentsList, showPayments: true });
  }
  render() {
    return (
      <>
        {this.state.showPayments && (
          <Card>
            <Card.Header>
              <h5 className="fs-0 text-capitalize">Payments</h5>
            </Card.Header>
            <Card.Body>
              <Table responsive className="table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      <small>Actions</small>
                    </th>
                    <th scope="col">
                      <small>Date</small>
                    </th>
                    <th scope="col">
                      <small>Amount</small>
                    </th>
                    <th scope="col">
                      <small>Line Ref</small>
                    </th>
                    <th scope="col">
                      <small>Contact</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.payments.map((p, index) => (
                    <tr key={index} id={index}>
                      <td>
                        <ActionButton
                          icon="edit"
                          title="Edit"
                          variant="action"
                          className="p-0 me-2"
                        />
                        <ActionButton
                          icon="trash-alt"
                          title="Delete"
                          variant="action"
                          className="p-0"
                        />
                      </td>
                      <td>
                        <small>{p.date}</small>
                      </td>
                      <td>
                        <small>{'£' + p.amount}</small>
                      </td>
                      <td>
                        <small>{p.ref}</small>
                      </td>
                      <td>
                        <small>{p.contact}</small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
      </>
    );
  }
}

InvoicePaymentsList.propTypes = {
  paymentsList: PropTypes.any
};

export default InvoicePaymentsList;
