import React from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
//import team1 from 'assets/img/team/3.jpg';
import { managementEvents } from 'data/dashboard/projectManagement';
import AbsenceThisYear from './AbsenceThisYear';
import SharedFiles from './SharedFiles';
import ActiveUsers from './ActiveUsers';
import CalendarManagement from 'components/app/management-events/CalendarManagement';
import ToDoList from './ToDoList';
import HolidayService from '../../app/holidays/HolidayService';
import EventService from '../../app/management-events/EventService';
import DirectoryService from 'components/app/directory/DirectoryService';
import DocumentService from 'components/app/documents/DocumentService';
import defaultAvatar from 'assets/img/team/avatar.png';
import AnnoucementWidget from 'components/app/annoucements/AnnoucementWidget';
import InternalVacanciesWidget from 'components/app/rolepositions/InternalVacanciesWidget';

class HRDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsers: [],
      outOfOfficeToday: [],
      sharedDocs: [],
      absencesByUsers: [],
      showAbsences: true,
      error: '',
      managementEvents: [],
      initialManagementEvents: [],
      isOpenScheduleModal: false,
      profile: null
    };
  }
  componentDidMount() {
    HolidayService.retrieveAbsences().then(data => {
      this.setState({ absencesByUsers: data });
      if (this.state.absencesByUsers.length > 0) {
        this.setState({ showAbsences: true });
      }
    });
    const { identifier } = DirectoryService.retrieveLoggedProfile();
    DirectoryService.retrieveProfile(identifier).then(profile => {
      this.setState({ profile: profile });
    });

    DirectoryService.retrieveUsersByAccount()
      .then(users => {
        this.setState({
          activeUsers: users.map(u => ({
            id: u.identifier,
            name: u.firstName + ' ' + u.lastName,
            avatar: {
              status: u.online === true ? 'online' : 'offline',
              src: u.avatar || defaultAvatar,
              size: '2xl'
            }
          }))
        });
        //this.setState({ outOfOfficeToday: this.state.activeUsers });
      })
      .catch(error => this.setState({ error: error.message }));

    DirectoryService.retrieveOutOfOfficeToday()
      .then(users => {
        this.setState({
          outOfOfficeToday: users.map(u => ({
            id: u.identifier,
            name: u.firstName + ' ' + u.lastName,
            avatar: {
              status: u.status || 'offline',
              src: u.avatar || defaultAvatar,
              size: '2xl'
            }
          }))
        });
      })
      .catch(error => this.setState({ error: error.message }));
    DocumentService.retrieveDocumentsByProfile().then(docs => {
      this.setState({
        sharedDocs: docs.map(d => ({
          id: d.identifier,
          img: d.img,
          name: d.name,
          time: d.dateCreated,
          user: d.profile.email,
          border: true
        }))
      });
    });
    console.log(managementEvents);
    EventService.retrieveManagementEventsForHome().then(data => {
      this.setState({ managementEvents: data });
    });
  }
  render() {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col md={6} xxl={4}>
            {this.state.showAbsences && null !== this.state.profile && (
              <AbsenceThisYear
                data={HolidayService.getValuesForAbsencesChart(
                  this.state.profile,
                  this.state.absencesByUsers
                )}
                radius={['100%', '87%']}
              />
            )}
          </Col>
          <Col md={6} xxl={8}>
            <AnnoucementWidget />
          </Col>
        </Row>
        <Row className="g-3">
          <Col xxl={8}>
            <CalendarManagement data={this.state.managementEvents} />
          </Col>
          <Col xxl={4}>
            <ActiveUsers
              title={'Active users'}
              className="h-100"
              users={this.state.activeUsers}
            />
          </Col>
        </Row>
        <br></br>
        <Row className="g-3 mb-3">
          <Col lg={7} xl={8}>
            <ToDoList />
          </Col>
          <Col lg={5} xl={4}>
            <SharedFiles
              files={this.state.sharedDocs.slice(0, 5)}
              className="h-lg-100"
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col lg={7} xl={8}>
            <InternalVacanciesWidget />
          </Col>
          <Col lg={5} xl={4}>
            <ActiveUsers
              className="h-40"
              title={'Out of office today'}
              users={this.state.outOfOfficeToday}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default HRDashboard;
