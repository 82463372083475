import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ErrorPage from '../ErrorPage';

const withErrorHandling = WrappedComponent => {
  return props => {
    const [error, setError] = useState(false);

    useEffect(() => {
      const checkBackendStatus = async () => {
        try {
          await axios.get('/api/health-check');
          setError(false);
        } catch (err) {
          setError(true);
        }
      };

      checkBackendStatus();

      const intervalId = setInterval(checkBackendStatus, 5000);

      return () => clearInterval(intervalId);
    }, []);

    if (error) {
      return <ErrorPage />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withErrorHandling;
