const goalSchema = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'targetValue',
        label: 'Target Value',
        type: 'text',
        showInList: true
      },
      {
        name: 'deadline',
        label: 'Deadline',
        type: 'date',
        showInList: true
      }
    ]
  },
  {
    name: 'progress',
    label: 'Progress',
    type: 'multiselect',
    isMulti: false,
    required: true,
    showInList: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'editor',
    required: true
  }
];
export default goalSchema;
