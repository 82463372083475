import React from 'react';
import { Card, Row, Col, Button, Dropdown } from 'react-bootstrap';
import InvoicesList from './InvoicesList';
import { Tabs, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FormControl, InputGroup } from 'react-bootstrap';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import { accountancyStats } from 'data/dashboard/saas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class InvoicesLandingPage extends React.Component {
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <Row className="flex-center">
              <Col>
                <Flex>
                  <div className="fs--1 ms-2 flex-1">
                    <h4 className="fs-0 text-capitalize">Invoices</h4>
                  </div>
                </Flex>
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Flex>
                  <Button
                    variant="falcon-success"
                    size="sm"
                    className="px-4 px-sm-5"
                    href="/app/invoices/create-invoice"
                  >
                    Create Invoice
                  </Button>
                  <Dropdown>
                    <Dropdown.Toggle variant="falcon-default"></Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">
                        Create a new Recurring Invoice
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Create new Credit Note
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Flex>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row className="g-3 mb-3">
              {accountancyStats.slice(1, 4).map((stat, index) => (
                <Col key={index}>
                  <StatisticsCard
                    stat={stat}
                    style={{ minHeight: '10rem', minWidth: '12rem' }}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
        <br></br>
        <Card>
          <Card.Body>
            <Tabs defaultActiveKey="all" id="controlled-tab-example">
              <Tab
                eventKey="all"
                title="All"
                className="border-bottom border-x p-3"
              >
                <Row>
                  <Col>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon
                          icon="search"
                          className="text-400 search-box-icon"
                        />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <InvoicesList></InvoicesList>
              </Tab>
              <Tab
                eventKey="outstanding"
                title="Outstanding"
                className="border-bottom border-x p-3"
              >
                <InvoicesList></InvoicesList>
              </Tab>
              <Tab
                eventKey="overdue"
                title="Overdue"
                className="border-bottom border-x p-3"
              >
                <InvoicesList></InvoicesList>
              </Tab>
              <Tab
                eventKey="paid"
                title="Paid"
                className="border-bottom border-x p-3"
              >
                <InvoicesList></InvoicesList>
              </Tab>
              <Tab
                eventKey="drafts"
                title="Drafts"
                className="border-bottom border-x p-3"
              >
                <InvoicesList></InvoicesList>
              </Tab>
              <Tab
                eventKey="recurring"
                title="Recurring"
                className="border-bottom border-x p-3"
              >
                <InvoicesList></InvoicesList>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default InvoicesLandingPage;
