import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import defaultAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { Card } from 'react-bootstrap';
import './ColleagueCard.css';

const ColleagueCard = ({ colleague }) => {
  const { avatar, firstName, email, lastName, identifier } = colleague;
  const avatarSrc = avatar;
  let linkToProfile = '/social/directory/user-details/' + identifier;
  return (
    <Card>
      <Card.Body>
        <Link to={linkToProfile}>
          <Avatar
            size="4xl"
            className="avatar-profile avatar-colleague-card"
            src={avatarSrc ? avatarSrc : defaultAvatar}
            mediaClass="img-thumbnail shadow-sm"
          />
        </Link>
      </Card.Body>
      <Card.Footer>
        <h6 className="mb-1">
          <Link to={linkToProfile}>
            {firstName} {lastName}
          </Link>
        </h6>
        <p className="fs--2 mb-1">
          <Link className="text-700" to={'mailto:' + email}>
            {email}
          </Link>
        </p>
      </Card.Footer>
    </Card>
  );
};

ColleagueCard.propTypes = {
  colleague: PropTypes.shape({
    avatar: PropTypes.any,
    avatarSrc: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    identifier: PropTypes.string,
    lastName: PropTypes.string
  })
};

export default ColleagueCard;
