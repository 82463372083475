import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import RoleService from './RolesService';
const RoleStatusModal = ({
  isOpenRoleApprovedModal,
  setOpenRoleApprovedModal,
  setStatus,
  identifier,
  newStatus
}) => {
  const [formData, setFormData] = useState();
  const [isDark, setIsDark] = useState(true);
  const [validated, setValidated] = useState(false);

  const handleSubmit = () => {
    const updatedFormData = {
      ...formData,
      status: newStatus,
      identifier: identifier
    };
    setIsDark(true);
    setStatus(updatedFormData.status);
    RoleService.updateStatus(updatedFormData)
      .then(() => {
        setOpenRoleApprovedModal(false);
        setValidated(true);
      })
      .catch(error => console.log(error));
  };
  const handleClose = () => {
    setOpenRoleApprovedModal(false);
  };
  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Form
        className="mb-2"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Modal
          show={isOpenRoleApprovedModal}
          onHide={handleClose}
          contentClassName="border"
        >
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title as="h5">Approve Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label className="fs-0">Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={20}
                name="log"
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="bg-light px-card border-top-0">
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              className="px-4 mx-0"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

RoleStatusModal.propTypes = {
  isOpenRoleApprovedModal: PropTypes.bool.isRequired,
  setOpenRoleApprovedModal: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  identifier: PropTypes.string.isRequired,
  newStatus: PropTypes.string.isRequired
};
export default RoleStatusModal;
