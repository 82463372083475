import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import textfile from '../../../assets/tnc.txt';
import { useState, useEffect } from 'react';

const TnCModal = ({ isModalOpen, handleClose }) => {
  const [tnCText, setTnCText] = useState();

  useEffect(() => {
    fetch(textfile)
      .then(response => response.text())
      .then(textContent => {
        setTnCText(textContent);
      });
  }, []);
  return (
    <>
      <Modal show={isModalOpen} onHide={handleClose} contentClassName="border">
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h5">Terms & Conditions apply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label className="fs-0">Read carefully</Form.Label>
            <Form.Control
              as="textarea"
              rows={20}
              name="comments"
              value={tnCText}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleClose(false)}
            className="px-4 mx-0"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

TnCModal.propTypes = {
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.any
};
export default TnCModal;
