import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import PerformanceReviewService from './PerformanceReviewService';
import GoalService from './GoalService';
import DirectoryService from '../directory/DirectoryService';
import schema from './jsonSchema';
const jsonSchema = schema;

const mergedJsonSchema = (jsonSchema, goals, users) => {
  const reviewerList = users;
  return jsonSchema.map(field => {
    if (field.name === 'goals') {
      return {
        ...field,
        options: goals
      };
    }
    if (field.name === 'employee') {
      return {
        ...field,
        options: reviewerList
      };
    }
    if (field.name === 'reviewer') {
      console.log(field.name);
      return {
        ...field,
        options: reviewerList
      };
    }
    return field;
  });
};
const handlePerformanceReviewFormSubmit = formData => {
  PerformanceReviewService.savePerformanceReview(formData)
    .then(response => console.log(response))
    .catch(error => console.log(error));
};
const PerformanceReviewForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [optionsValues, setOptionsValues] = useState();
  const [users, setUsers] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (identifier) {
          const reviewData =
            await PerformanceReviewService.retrievePerformanceReview(
              identifier
            );
          reviewData.employee = {
            label:
              reviewData.employee.firstName +
              ' ' +
              reviewData.employee.lastName,
            value: reviewData.employee.identifier
          };
          reviewData.reviewer = {
            label:
              reviewData.reviewer.firstName +
              ' ' +
              reviewData.reviewer.lastName,
            value: reviewData.reviewer.identifier
          };
          const goalOptions = reviewData.goals.map(goal => ({
            label: goal.name,
            value: goal.identifier
          }));
          reviewData.goals = goalOptions;

          setData(reviewData);
          console.log(reviewData);
        }
        const goals = await GoalService.retrieveAllGoalByAccount();

        const mappedGoals = goals.map(g => ({
          value: g.identifier,
          label: g.name
        }));
        setOptionsValues(mappedGoals);

        const usersByAccount = await DirectoryService.retrieveUsersByAccount();
        const mappedUsers = usersByAccount.map(u => ({
          value: u.identifier,
          label: u.firstName + ' ' + u.lastName
        }));
        setUsers(mappedUsers);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);
  /*const handlePerformanceReviewFormSubmit = formData => {
    PerformanceReviewService.savePerformanceReview(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };*/

  const buttonsConf = [
    {
      name: 'Save Review',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save PerformanceReview');
      }
    },
    {
      name: 'Save Draft',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Save Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Upload a review',
    ' ',
    buttonsConf
  );

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'PerformanceReview  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              optionsValues={optionsValues}
              users={users}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, optionsValues, users }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, optionsValues, users),
    handlePerformanceReviewFormSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  optionsValues: PropTypes.any,
  users: PropTypes.any
};
export default PerformanceReviewForm;
