import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import React from 'react';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { isIterableArray } from 'helpers/utils';
import FalconDropzone from 'components/common/FalconDropzone';
import PropTypes from 'prop-types';

const UploadAvatar = ({ setUserAvatarFile, userFile }) => {
  const [avatar, setAvatar] = useState([]);
  const [value, setValue] = useState();
  console.log(value);
  console.log(userFile);
  /* const getImageBlob = (url) => {
        return new Promise( async resolve=>{
          let resposne = await fetch( url );
          let blob = resposne.blob();
          resolve( blob );
        });
      };
      
      // convert a blob to base64
      const blobToBase64 = (blob) => {
        return new Promise( resolve=>{
          let reader = new FileReader();
          reader.onload = function() {
            let dataUrl = reader.result;
            resolve(dataUrl);
          };
          reader.readAsDataURL(blob);
        });
      };
// combine the previous two functions to return a base64 encode image from url
 const  getBase64Image = async ( url )=> {
    let blob = await getImageBlob( url );
    let base64 = await blobToBase64( blob );
    return base64;
  };
    if(userFile !== undefined){
        getBase64Image( 'file://' + userFile ).then( base64Image=> console.log( base64Image) )
    } */
  return (
    <>
      <Row className="mb-3">
        <Col md="auto">
          <Avatar
            size="4xl"
            src={
              isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src : ''
            }
          />
        </Col>
        <Col md>
          <FalconDropzone
            files={avatar}
            onChange={files => {
              setAvatar(files);
              setValue('avatar', files);
              setUserAvatarFile(files);
              console.log(files);
            }}
            multiple={false}
            accept="image/*"
            placeholder={
              <>
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">
                    Upload your profile picture
                  </p>
                </Flex>
                <p className="mb-0 w-75 mx-auto text-400">
                  Upload a 300x300 jpg image with a maximum size of 400KB
                </p>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};
UploadAvatar.propTypes = {
  userFile: PropTypes.string,
  setUserAvatarFile: PropTypes.func.isRequired
};
export default UploadAvatar;
