import DemoService from '../common/functions/DemoService';

const AnnoucementService = {
  async saveAnnoucement(formData) {
    try {
      let url = '/api/annoucement';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;

      console.log(profileLogged);
      console.log(accountIdentifier);

      if (formData.identifier !== undefined) {
        url = '/api/annoucement/' + formData.identifier;
        method = 'PUT';
        delete formData.profile;
      }
      const audienceValues = formData.audience.filter(
        x => x.type === 'profile'
      );
      const groupsValues = formData.audience.filter(x => x.type === 'group');
      const updatedFormData = {
        ...formData,
        profileIdentifier: profileLogged.identifier,
        audienceValues: audienceValues,
        groupsValues: groupsValues
      };
      delete updatedFormData.audience;

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });
      if (response.ok) {
        const newAnnoucement = await response.json();
        console.log('Annoucement has been updated:', newAnnoucement);
      }
    } catch (error) {
      console.log('Error saving Annoucement', error);
    }
  },
  async retrieveAllAnnoucementsByProfile() {
    try {
      const { identifier } = JSON.parse(localStorage.getItem('profile'));

      const response = await fetch('/api/annoucement/profile/' + identifier);
      const data = await response.json();
      if (data.length === 0) {
        return DemoService.retrieveDemoAnnoucements();
      }
      return data;

      // return DemoService.retrieveDemoAnnoucements();

      //return data;
    } catch (error) {
      console.error('Error fetching Annoucement', error);
      throw error;
    }
  },
  async retrieveAnnoucement(identifier) {
    try {
      const response = await fetch('/api/annoucement/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Annoucement', error);
      throw error;
    }
  },
  async deleteAnnoucement(identifier) {
    try {
      let url = '/api/annoucement/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newAnnoucement = await response.json();
        console.log('Annoucement has been deleted:', newAnnoucement);
      }
    } catch (error) {
      console.log('Error deleting Annoucement', error);
    }
  }
};
export default AnnoucementService;
