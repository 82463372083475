import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const DateInput = ({ id, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);
  const [isValid, setIsValid] = useState(true);

  const handleDateChange = e => {
    e.preventDefault();

    const inputDate = e.target.value;
    const isValidDate = validateDate(inputDate);
    setIsValid(isValidDate);
    setInputValue(inputDate);
    if (isValidDate) {
      onChange(e);
    }
  };

  const validateDate = inputDate => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(inputDate);
  };

  return (
    <>
      <Form.Control
        type="text"
        id={id}
        size="sm"
        value={inputValue}
        onChange={e => handleDateChange(e)}
        isInvalid={!isValid}
        placeholder="DD/MM/YYYY"
      />
      <Form.Control.Feedback type="invalid">
        Please enter a valid date in DD/MM/YYYY format.
      </Form.Control.Feedback>
    </>
  );
};

DateInput.propTypes = {
  id: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any
};

export default DateInput;
