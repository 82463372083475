class UserProfile {
  identifier;
  firstname;
  lastname;
  email;
  deleted;
  accountIdentifier;
  dob;
  entitlementAbsence;
  avatar;
  title;
  knownAs;
  address;
  gender;
  genderIdentity;
  preferredPronoun;
  maritalStatus;
  employeeNumber;
  workPhone;
  workExtn;
  workMobile;
  personalEmail;
  personalMobile;
  homePhone;
  contractIdentifier;
}
export default UserProfile;
