import React, { Component } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import { useParams } from 'react-router';
import UploadAvatar from './UploadAvatar';
import axios from 'axios';
import DirectoryService from './DirectoryService';
import PropTypes from 'prop-types';
export function withRouter(Child) {
  return props => {
    const params = useParams();
    return <Child {...props} params={params} />;
  };
}

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      firstName: '',
      lastName: '',
      email: '',
      identifier: null,
      entitlementAbsence: 0,
      userAvatar: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setUserAvatar = this.setUserAvatar.bind(this);
  }
  componentDidMount() {
    if (!DirectoryService.isLoggedByHRAdmin()) {
      alert("You don't have permissions!");
    }
    const { identifier } = this.props.params;
    if (null !== identifier) {
      fetch('http://localhost:8099/profile/' + identifier)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({
            ...this.state, // Not required but just a heads up on using mutation
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.lastName,
            identifier: data.identifier,
            entitlementAbsence: data.entitlementAbsence,
            userAvatar: data.avatar
          });
          console.log(this.state);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
  setUserAvatar(avatarFile) {
    console.log(avatarFile[0]);
    this.setState({ userAvatar: avatarFile });
  }

  validated(event) {
    console.log(event);
    return this.state.validated;
  }
  handleChange(e) {
    console.log(e.target);
    this.setState({
      firstName: e.target.firstName,
      lastName: e.target.lastName,
      email: e.target.email,
      entitlementAbsence: e.target.entitlementAbsence
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    const sharedFile = this.state.avatarFile;
    const formData = new FormData();
    formData.append('file', sharedFile);
    formData.append(
      'profile',
      JSON.stringify({
        firstName: formData.firstName.value,
        email: formData.email.value,
        lastName: formData.lastName.value,
        entitlementAbsence: formData.entitlementAbsence.value
      })
    );
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    let url = 'http://localhost:8099/profile';
    if (this.state.identifier !== undefined) {
      url += '/' + this.state.identifier;
      axios.put(url, formData, config).then(response => {
        console.log(response.data);
        window.location.href =
          '/social/directory/profile/' + response.data.identifier;
      });
    } else {
      axios.post(url, formData, config).then(response => {
        console.log(response.data);
        window.location.href =
          '/social/directory/profile/' + response.data.identifier;
      });
    }
  }

  render() {
    return (
      <>
        <Form
          className="mb-3"
          noValidate
          validated={this.validated}
          onSubmit={this.handleSubmit}
        >
          <FloatingLabel
            controlId="floatingInput"
            label="First Name"
            className="mb-3"
          >
            <Form.Control
              id="firstName"
              type="text"
              required
              value={this.state.firstName}
              onChange={this.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Last Name"
            className="mb-3"
          >
            <Form.Control
              id="lastName"
              type="text"
              required
              value={this.state.lastName}
              onChange={this.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Email"
            className="mb-3"
          >
            <Form.Control
              id="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Entitlement Absence"
            className="mb-2"
          >
            <InputGroup className="mb-2">
              <Form.Control
                placeholder="Entitlement Absence"
                aria-label="Entitlement Absence"
                aria-describedby="basic-addon2"
                id="entitlementAbsence"
                type="text"
                value={this.state.entitlementAbsence}
                onChange={this.handleChange}
              />
              <InputGroup.Text id="entitlementAbsenceGroup">
                days
              </InputGroup.Text>
            </InputGroup>
          </FloatingLabel>
          <UploadAvatar
            setUserAvatarFile={this.setUserAvatar}
            userFile={this.state.userAvatar}
          ></UploadAvatar>
          <Button type="submit">Submit form</Button>
        </Form>
      </>
    );
  }
}

UserForm.propTypes = {
  params: PropTypes.shape({
    identifier: PropTypes.string
  })
};

export default withRouter(UserForm);
