const filterDataForList = (data, attributesToInclude, transformFunction) => {
  return data.map(item => {
    const filteredItem = {};

    // Iterate through each attribute to include
    attributesToInclude.forEach(attrName => {
      // Check if the attribute exists in the item
      if (Object.prototype.hasOwnProperty.call(item, attrName)) {
        // Apply the optional transformation function if provided
        if (typeof transformFunction === 'function') {
          filteredItem[attrName] = transformFunction(attrName, item);
        } else {
          filteredItem[attrName] = item[attrName];
        }
      }
    });

    return filteredItem;
  });
};

export default filterDataForList;
