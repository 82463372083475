import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import React, { useState, useEffect } from 'react';
import FormModal from 'components/app/common/FormModal';
import EventService from '../EventService';
import { useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import schema from './jsonSchema';
import { useNavigate } from 'react-router-dom';
const formatDate = inputDate => {
  const date = new Date(inputDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};
const ManagementEventDetails = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const buttonsConf = [
    {
      name: 'Edit Event',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this event');
        navigate('/management-events/' + identifier + '/edit');
      }
    }
  ];
  const jsonSchema = schema;
  const headerGenerated = useHeaderGenerator('Event', ' ', buttonsConf);

  const [modalShow, setModalShow] = useState(false);
  const [event, setEvent] = useState();
  const [attendeesValues, setAttendeesValues] = useState();
  const showValue = attributeName => {
    if ('attendees' === attributeName) {
      return event.attendees.map(a => {
        console.log(a);
        return (
          <>
            <Link
              to={'/social/directory/user-details/' + a.value}
              className="badge border link-secondary text-decoration-none me-1"
            >
              {a.label}
            </Link>
          </>
        );
      });
    }
    if ('label' === attributeName) {
      return (
        <>
          <Link
            to="#"
            className="badge border link-secondary text-decoration-none me-1"
          >
            {event[attributeName]}
          </Link>
        </>
      );
    }
    if ('start' === attributeName || 'end' === attributeName) {
      return formatDate(event[attributeName]);
    }
    return event[attributeName];
  };
  useEffect(() => {
    EventService.retrieveManagementEventByIdentfier(identifier)
      .then(event => {
        delete event.profile;
        setEvent(event);
        const attendees = event.attendees.map(a => ({
          value: a.identifier,
          label: a.firstName + ' ' + a.lastName
        }));
        event.attendees = attendees;
        setAttendeesValues(attendees);
        const updatedFormData = {
          ...event, // Merge the properties of `event` into the new object
          attendeesValues: attendeesValues // Add the `attendeesValues` property to the new object
        };
        console.log(updatedFormData);
      })
      .catch(error => {
        console.error('Error retrieving event', error);
      });
  }, []);
  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Event has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {event && (
        <>
          <Card>
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col>{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default ManagementEventDetails;
