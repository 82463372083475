const jsonSchema = [
  {
    name: 'owner',
    label: 'Created by',
    type: 'text',
    showInList: true
  },
  {
    name: 'message',
    label: 'Message',
    type: 'text',
    showInList: true,
    required: true
  },
  {
    name: 'time',
    label: 'Time',
    type: 'date',
    pattern: 'dd/MM/yyyy',
    required: true,
    showInList: true
  }
];

export default jsonSchema;
