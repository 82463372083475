import DirectoryService from './DirectoryService';

const SettingsService = {
  async retrieveSettings() {
    const { accountIdentifier } = DirectoryService.retrieveLoggedProfile();
    try {
      const response = await fetch('/api/settings/' + accountIdentifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user', error);
      throw error;
    }
  },
  async saveSettings(formData) {
    try {
      let url = '/api/settings';
      let method = 'PUT';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;

      if (formData.identifier === undefined) {
        url = '/api/settings';
        method = 'POST';
        formData.identifier = accountIdentifier;
      } else {
        url += '/' + accountIdentifier;
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newGoal = await response.json();
        console.log('Settings has been updated:', newGoal);
      }
    } catch (error) {
      console.log('Error saving Settings', error);
    }
  }
};

export default SettingsService;
