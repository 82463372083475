import PropTypes from 'prop-types';
import React from 'react';

//import * as d3 from 'd3';

const OrgChartNode = ({ node }) => {
  const avatarSize = 40; // Adjust the size as needed
  const padding = 10; // Adjust the padding as needed
  const width = 150;
  return (
    <g transform={`translate(${node.x},${node.y})`}>
      <rect
        width={150}
        height={100}
        rx={10}
        ry={10}
        fill="#f0f0f0"
        stroke="#ccc"
        strokeWidth={1}
      />
      {/* Avatar */}
      <circle
        cx={width / 2}
        cy={padding + avatarSize / 2}
        r={avatarSize / 2}
        fill="#5bc0de"
      />

      {/* Name */}
      <text
        x={width / 2}
        y={padding + avatarSize + 20}
        textAnchor="middle"
        fill="#333"
      >
        {node.data.name}
      </text>

      {/* Role */}
      <text
        x={width / 2}
        y={padding + avatarSize + 40}
        textAnchor="middle"
        fill="#999"
      >
        {node.data.role}
      </text>
    </g>
  );
};

OrgChartNode.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      name: PropTypes.any,
      role: PropTypes.any
    }),
    x: PropTypes.any,
    y: PropTypes.any
  })
};

const OrgChartLink = ({ link }) => {
  return (
    <path
      d={`M${link.source.x},${link.source.y}L${link.target.x},${link.target.y}`}
      fill="none"
      stroke="#bdc3c7"
      strokeWidth="2"
    />
  );
};

OrgChartLink.propTypes = {
  link: PropTypes.shape({
    source: PropTypes.shape({
      x: PropTypes.any,
      y: PropTypes.any
    }),
    target: PropTypes.shape({
      x: PropTypes.any,
      y: PropTypes.any
    })
  })
};

const OrgChart = () => {
  // Assuming data is in the format { name: "John", children: [...] }
  //const hierarchy = d3.hierarchy(data);

  //const treeLayout = d3.tree().size([600, 400]);
  //const treeData = treeLayout(hierarchy);

  return (
    <>
      <h1>OrgChart</h1>
    </>
  );
};

export default OrgChart;
