import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import AnnoucementService from './AnnoucementService';
import AnnoucementItem from './AnnoucementItem';
import { Link } from 'react-router-dom';
import FalconCardHeader from 'components/common/FalconCardHeader';

const AnnoucementWidget = () => {
  const [data, setData] = useState();

  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);

  useEffect(() => {
    AnnoucementService.retrieveAllAnnoucementsByProfile()
      .then(response => {
        let updatedData = response
          .filter(d => d.pingToTheTop === true)
          .map(d => {
            delete d.audience;
            return d;
          });

        setData(updatedData.slice(0, 1));
        setNoDataToShow(!updatedData.length > 0);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);

  return (
    <>
      {error}
      {data && (
        <Card>
          <FalconCardHeader
            title="Annoucements"
            titleTag="h6"
            className="py-2"
            light
            endEl={
              <Link
                className="py-1 fs--1 font-sans-serif"
                to="/app/annoucements"
              >
                View All
              </Link>
            }
          />
          <Card.Body className="p-0">
            {data.map(annoucement => (
              <AnnoucementItem {...annoucement} key={annoucement.identifier} />
            ))}
            <br />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default AnnoucementWidget;
