import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';

const LoginForm = ({ hasLabel, layout, onSubmit }) => {
  // State
  console.log(layout);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3 m-2">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3 m-2">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      <div className="d-grid gap-2 mx-2">
        <Button
          type="submit"
          color="primary"
          disabled={!formData.email || !formData.password}
        >
          Sign in
        </Button>
      </div>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  layout: PropTypes.string,
  onSubmit: PropTypes.any
};

export default LoginForm;
