import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import GroupService from './GroupService';
import schema from './jsonSchema';
import useTableGenerator from '../common/functions/useTableGenerator';

import membersSchema from './membersSchema';
import DirectoryService from '../directory/DirectoryService';

const membersJsonSchema = membersSchema;

const GroupDetails = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [group, setGroup] = useState();
  const [members, setMembers] = useState();
  const [membersTable, setMembersTable] = useState();
  const showValue = attributeName => {
    return group[attributeName];
  };
  const actionButtons = [
    {
      icon: 'edit',
      onClick: identifier => {
        console.log(identifier);
        navigate('/social/directory/user-details/' + identifier);
      },
      className: 'p-0 me-2',
      title: 'Edit',
      variant: 'action'
    }
  ];
  useEffect(() => {
    if (identifier) {
      GroupService.retrieveGroup(identifier)
        .then(data => {
          console.log(data);
          setMembersTable(
            useTableGenerator(data.members, membersJsonSchema, actionButtons)
          );
          setMembers(data.members);
          console.log(members);
          delete data.members;

          setGroup(data);
        })
        .catch(error => console.log(error));
    }
  }, []);

  const buttonsConf = [
    {
      name: 'Edit Group',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this group');
        navigate('/app/groups/' + identifier + '/edit');
      }
    }
  ];
  const deleteButton = {
    name: 'Delete',
    type: 'button',
    variant: 'warning',
    onClick: () => {
      console.log('Delete Group');
      GroupService.deleteGroup(identifier)
        .then(data => {
          console.log(data);
          navigate('/app/groups');
        })
        .catch(error => console.log(error));
    }
  };
  if (DirectoryService.isLoggedByHRAdmin() === true) {
    buttonsConf.push(deleteButton);
  }
  const jsonSchema = schema;
  const headerGenerated = useHeaderGenerator('Group', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Group has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}

      {group && (
        <>
          <Card className="mb-2">
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  if (showValue(field.name)) {
                    return (
                      <Row key={field.name}>
                        <Col xs={5} sm={4}>
                          <p className="fw-semi-bold mb-1">
                            {field.label + ': '}
                          </p>
                        </Col>
                        <Col>{showValue(field.name)}</Col>
                      </Row>
                    );
                  }
                }
              })}
            </Card.Body>
          </Card>
          {membersTable}
        </>
      )}
    </>
  );
};

export default GroupDetails;
