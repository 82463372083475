import React from 'react';
import { Button } from 'react-bootstrap';

function useButtonsGenerator(buttons) {
  return (
    <>
      {buttons.map((button, variant) => (
        <Button
          key={button.name}
          size="sm"
          type={button.type}
          onClick={button.onClick}
          variant={button.variant || variant || 'falcon-default'}
          className="me-2"
        >
          {button.name}
        </Button>
      ))}
    </>
  );
}

export default useButtonsGenerator;
