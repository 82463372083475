import DirectoryService from '../directory/DirectoryService';
const DocumentService = {
  async retrieveDocumentsByProfile() {
    try {
      const { identifier } = DirectoryService.retrieveLoggedProfile();
      const response = await fetch('/api/document/profile/' + identifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error featching documents', error);
      throw error;
    }
  },
  async saveDocument(formData) {
    try {
      let url = '/api/<your_controller>';
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { accountIdentifier } = profileLogged;
        console.log(profileLogged);
        console.log(accountIdentifier);
        url = '/api/<your_controller>';
        method = 'POST';
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newDocument = await response.json();
        console.log('Document has been updated:', newDocument);
      }
    } catch (error) {
      console.log('Error saving Document', error);
    }
  },
  async retrieveAllDocumentByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/<your_controller>' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Document', error);
      throw error;
    }
  },
  async retrieveDocument(identifier) {
    try {
      const response = await fetch('/api/<your_controller>/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Document', error);
      throw error;
    }
  },
  async deleteDocument(docIdentifier, profileIdentifier) {
    const { identifier } = DirectoryService.retrieveLoggedProfile();
    if (identifier === profileIdentifier) {
      try {
        let url = '/api/document/' + docIdentifier;
        let method = 'DELETE';
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ update: true })
        });
        if (response.ok) {
          const newDocument = await response.json();
          console.log('Document has been deleted:', newDocument);
        }
      } catch (error) {
        console.log('Error deleting Document', error);
      }
    } else {
      throw new Error("You don't have permissions to delete this document");
    }
  }
};
export default DocumentService;
