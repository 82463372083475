const jsonSchema = [
  {
    name: 'email',
    label: 'Email',
    type: 'multiselect',
    isMulti: true,
    required: true,
    showInList: true
  },
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
    showInList: true,
    type: 'multiselect',
    isMulti: false
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'multiselect',
    showInList: true,
    isMulti: false,
    required: true
  }
];
export default jsonSchema;
