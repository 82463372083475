const jsonSchema = [
  {
    name: 'topic',
    label: 'Topic',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'pingToTheTop',
        label: 'Ping to the top',
        type: 'checkbox',
        showInList: false
      },
      {
        name: 'emailNotification',
        label: 'Email Notification',
        type: 'checkbox',
        required: false
      }
    ]
  },
  {
    name: 'audience',
    label: 'Audience',
    type: 'multiselect',
    showInList: false,
    isMulti: true,
    required: false
  },
  {
    name: 'body',
    label: 'Body',
    type: 'editor',
    required: true,
    showInList: true
  }

  // Add more fields as needed
];
export default jsonSchema;
