const jsonSchema = [
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'password',
        label: 'Password',
        required: true,
        type: 'password'
      },
      {
        name: 'repeatPassword',
        label: 'Repeat Password',
        required: true,
        type: 'password'
      }
    ]
  }
];
export default jsonSchema;
