const GoalService = {
  async saveGoal(formData) {
    try {
      let url = '/api/performance/goal';
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { accountIdentifier, identifier } = profileLogged;
        console.log(profileLogged);
        console.log(accountIdentifier);
        formData.profileIdentifier = identifier;
        url = '/api/performance/goal';
        method = 'POST';
      } else {
        url += '/' + formData.identifier;
      }
      const updatedFormData = {
        ...formData,
        progress: formData.progress.value
      };
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });
      if (response.ok) {
        const newGoal = await response.json();
        console.log('Goal has been updated:', newGoal);
      }
    } catch (error) {
      console.log('Error saving Goal', error);
    }
  },
  async retrieveAllGoalByAccount() {
    try {
      const { identifier } = JSON.parse(localStorage.getItem('profile'));
      const url = '/api/performance/goal/profile/' + identifier;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Goal', error);
      throw error;
    }
  },
  async retrieveGoal(identifier) {
    try {
      const response = await fetch('/api/performance/goal/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Goal', error);
      throw error;
    }
  },
  async deleteGoal(identifier) {
    try {
      let url = '/api/performance/goal/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newGoal = await response.json();
        console.log('Goal has been deleted:', newGoal);
      }
    } catch (error) {
      console.log('Error deleting Goal', error);
    }
  }
};
export default GoalService;
