import React from 'react';
import { Card, Col, Button, Row, Alert } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import RoleService from '../rolepositions/RolesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
class NewPositionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      roles: [],
      recalculateBudget: false
    };
  }

  componentDidMount() {
    const columns = [
      {
        accessor: 'identifier',
        Header: 'Calculate',
        Cell: rowData => {
          console.log(rowData);
          const icon = 'edit';
          return (
            <Button
              onClick={() =>
                this.setState({
                  recalculateBudget: !this.state.recalculateBudget
                })
              }
              icon="edit"
              title="Edit"
              variant="action"
              className="p-0 me-2"
            >
              <FontAwesomeIcon icon={icon} />
            </Button>
          );
        }
      },
      {
        accessor: 'header',
        Header: 'Header',
        Cell: rowData => {
          const { identifier } = rowData.row.original;
          const { header } = rowData.row.original;
          return <a href={'/app/roles/role/' + identifier}>{header}</a>;
        }
      },
      {
        accessor: 'status',
        Header: 'Status'
      },
      {
        accessor: 'grade',
        Header: 'Grade'
      },
      {
        accessor: 'contractType',
        Header: 'Type'
      },

      {
        accessor: 'requestedBy',
        Header: 'Requested By',
        Cell: rowData => {
          const { requestedBy } = rowData.row.original;
          return requestedBy.firstName + ' ' + requestedBy.lastName;
        }
      }
    ];
    RoleService.retrieveRolesByUser().then(roles =>
      this.setState({
        roles: roles.filter(r => r.status === 'Accepted'),
        columns: columns
      })
    );
  }
  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <AdvanceTableWrapper
              columns={this.state.columns}
              data={this.state.roles}
              sortable
              pagination
              perPage={20}
            >
              <AdvanceTable
                table
                headerClassName="bg-100 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={this.state.roles.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
        <br />
        {this.state.recalculateBudget && (
          <Card>
            <Card.Header>
              <h5 className="fs-0 text-capitalize">Calculate Budget</h5>
            </Card.Header>
            <Card.Body>
              <Alert key="warning" variant="warning">
                A budget holder / finance manager reviews the impact on budget
                (this would be automatically calculated)
              </Alert>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col md="auto" className="mt-4 mt-md-0">
                  <Flex>
                    <Button
                      variant="falcon-warning"
                      size="sm"
                      className="px-4 px-sm-5"
                      onClick={() => alert(1)}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="falcon-success"
                      size="sm"
                      className="px-4 px-sm-5"
                    >
                      Approve
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        )}
      </>
    );
  }
}

export default NewPositionsList;
