import React from 'react';
import { Card } from 'react-bootstrap';

const ErrorPage = () => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">503</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Service Unavailable
        </p>
        <hr />
        <p>
          Sorry, we are currently experiencing technical difficulties. Please
          try again later.
        </p>
      </Card.Body>
    </Card>
  );
};

export default ErrorPage;
