import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useDetailsGenerator from '../common/functions/useDetailsGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import DirectoryService from '../directory/DirectoryService';

import schema from './jsonSchema';
const jsonSchema = schema;

const CustomForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (undefined !== identifier) {
      DirectoryService.retrieveProfile(identifier)
        .then(data => {
          setData(data);
        })
        .catch(error => console.log(error));
    }
  }, [identifier]);
  const buttonsConf = [
    {
      name: 'Save Custom',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Custom');
      }
    },
    {
      name: 'Save Draft',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Save Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Create Custom',
    'description',
    buttonsConf
  );

  return (
    <>
      {headerGenerated}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData data={data} buttonsConf={buttonsConf} />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf }) => {
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { details } = useDetailsGenerator(data, jsonSchema);

  // Render your form using the formFields and event handlers
  return (
    <>
      {details}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any
};
export default CustomForm;
