import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import UsersCardDropdown from './UsersCardDropdown';
import { Card, Container } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import { Alert } from 'react-bootstrap';

const ActiveUsers = ({ users, end = 9, title, ...rest }) => {
  const [showUsersList, setShowUsersList] = useState(false);
  const [showNotUsersToShow, setShowNotUsersToShow] = useState(false);
  useEffect(() => {
    if (users.length > 0) {
      setShowUsersList(true);
    }
    console.log(showNotUsersToShow);
    console.log(end);
  }, [users]);
  return (
    <Card {...rest}>
      <FalconCardHeader
        light
        title={title}
        titleTag="h6"
        className="py-2"
        endEl={<UsersCardDropdown users={users} />}
      />
      <Card.Body className="py-2">
        <Container style={{ maxHeight: '320px', overflowY: 'auto' }}>
          {!showUsersList && (
            <Alert variant="info" onClose={() => setShowNotUsersToShow(false)}>
              <p className="mb-0 fs--1">There are not any collegues to show.</p>
            </Alert>
          )}
          {showUsersList && (
            <>
              {users.map(({ id, ...rest }, index) => (
                <ActiveUser
                  scrollbar
                  id={id}
                  {...rest}
                  key={id}
                  isLast={index === users.length - 1}
                />
              ))}
            </>
          )}
        </Container>
      </Card.Body>

      <FalconCardFooterLink
        title="All active users"
        to="/social/directory"
        size="sm"
      />
    </Card>
  );
};

const ActiveUser = ({ name, id, avatar, role, isLast }) => (
  <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
    <Avatar {...avatar} className={`status-${avatar.status}`} />
    <div className="ms-3">
      <h6 className="mb-0 fw-semi-bold">
        <Link className="text-900" to={'/social/directory/user-details/' + id}>
          {name}
        </Link>
      </h6>
      <p className="text-500 fs--2 mb-0">{role}</p>
    </div>
  </Flex>
);

ActiveUser.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  avatar: PropTypes.object,
  role: PropTypes.string,
  isLast: PropTypes.bool
};

ActiveUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(ActiveUser.propTypes)),
  end: PropTypes.number,
  title: PropTypes.string
};

export default ActiveUsers;
