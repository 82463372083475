import React from 'react';
import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';

function useHeaderGenerator(title, description, buttons) {
  return (
    <PageHeader title={title} description={description} className="mb-3">
      {buttons.map(button => (
        <Button
          key={button.name}
          size="sm"
          type={button.type}
          onClick={button.onClick}
          variant={button.variant || 'outline-primary'}
          className="me-2"
        >
          {button.name}
        </Button>
      ))}
    </PageHeader>
  );
}

export default useHeaderGenerator;
