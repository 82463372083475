import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import FormModal from '../common/FormModal';
import SettingsService from './SettingsService';

import jsonSchema from './settingsJSONSchema';

const schema = jsonSchema;
const BSTSettings = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  useEffect(async () => {
    await SettingsService.retrieveSettings()
      .then(data => {
        setData(data);
        setShowForm(true);
      })
      .catch(error => {
        console.log(error);
        setModalShow(true);
      });
  }, []);

  const handleSettingsFormSubmit = formData => {
    SettingsService.saveSettings(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Settings',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Settings');
      }
    },
    {
      name: 'Cancel',
      type: 'submit',
      variant: 'secondary',
      onClick: () => {
        console.log('Save Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator('Your settings', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Settings  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {showForm ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleSettingsFormSubmit}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    schema,
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any
};
export default BSTSettings;
