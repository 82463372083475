import PropTypes from 'prop-types';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';

const ProgressBarWidget = ({ data }) => {
  const variant = ['success', 'info', 'warning', 'danger'];
  console.log(data);
  return (
    <>
      <Card>
        <Background image={corner1} className="rounded-soft bg-card" />
        <Card.Header>
          <h5 className="display-6 mb-0">Progress</h5>
        </Card.Header>
        <Card.Body>
          {data.map((bar, index) => (
            <ProgressBar
              variant={variant[index]}
              now={bar.progress}
              key={index}
              label={bar.name.toLowerCase() + ' (' + bar.progress + '%)'}
              className="mb-2"
            />
          ))}
        </Card.Body>
      </Card>
    </>
  );
};

ProgressBarWidget.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  })
};

export default ProgressBarWidget;
