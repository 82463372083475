import React from 'react';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
//import expenses from 'data/expenses.js';
import InvoiceService from './InvoiceService';

class InvoicesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: []
    };
  }
  componentDidMount() {
    InvoiceService.retrieveAllInvoices().then(invoices => {
      console.log(invoices);
      this.setState({ data: invoices });
    });

    const columns = [
      {
        accessor: 'date',
        Header: 'Date'
      },
      {
        accessor: 'invoiceNumber',
        Header: 'Number',
        Cell: rowData => {
          const { identifier } = rowData.row.original;
          const { invoiceNumber } = rowData.row.original;
          return (
            <Link to={`/app/invoices/${identifier}`}>{invoiceNumber}</Link>
          );
        }
      },
      {
        accessor: 'contact',
        Header: 'Contact'
      },
      {
        accessor: 'dueDate',
        Header: 'Due'
      },
      {
        accessor: 'amount',
        Header: 'Amount'
      },
      {
        accessor: 'outstanding',
        Header: 'Outstanding',
        Cell: rowData => {
          const { outstanding } = rowData.row.original;
          const { status } = rowData.row.original;
          const statusType = status === 'paid' ? 'success' : 'warning';
          const statusIcon = status === 'paid' ? 'check' : 'stream';
          return (
            <>
              {'£' + outstanding + ' '}
              <SoftBadge pill bg={statusType}>
                {status}
                <FontAwesomeIcon icon={statusIcon} className="ms-2" />
              </SoftBadge>
            </>
          );
        }
      }
    ];
    this.setState({ columns: columns });
  }
  render() {
    return (
      <>
        <Card>
          <Card.Header></Card.Header>
          <Card.Body>
            <AdvanceTableWrapper
              columns={this.state.columns}
              data={this.state.data}
              sortable
              pagination
              perPage={20}
            >
              <AdvanceTable
                table
                headerClassName="bg-100 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={this.state.data.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default InvoicesList;
