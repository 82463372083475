import React, { useState, useEffect } from 'react';
import SupportTicketService from './SupportTicketService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { Link } from 'react-router-dom';

const SupportTicketList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const navigate = useNavigate();
  const transformData = (attr, item) => {
    console.log(attr);
    if (attr === 'title') {
      const url = '/app/support/tickets/' + item['identifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    if (attr === 'dateCreated') {
      const dateCreated = new Date(item['dateCreated']);
      return dateCreated.toUTCString();
    }
    return item[attr];
  };

  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    SupportTicketService.retrieveAllSupportTicketByAccount()
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformData
          )
        );
        setNoDataToShow(!data.length > 0);
      })
      .catch(e => {
        setError({ error: e.message });
        console.log(error);
      });
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new Ticket',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new SupportTicket');
        navigate('/app/support/tickets/create-form');
      }
    }
  ];
  const supportTicketDetails =
    'Resolve technical issues quickly and efficiently with our IT Support & Ticketing module. Empower employees to create and track support tickets for any IT-related concerns, ensuring that issues are addressed promptly. This module allows IT teams to prioritize requests, assign tasks, and communicate directly with users, all within a streamlined and transparent interface. With comprehensive reporting and monitoring tools, you can track ticket resolution times, identify recurring problems, and continuously improve your IT support services.';
  const headerGenerated = useHeaderGenerator(
    'IT Support & Ticketing',
    supportTicketDetails,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}

      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any tickets to show. Let's create a new one!
        </Alert>
      )}
      {data && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

export default SupportTicketList;
