import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import DirectoryService from 'components/app/directory/DirectoryService';

const QuickActionsDropdown = () => {
  const [picture, setPicture] = useState();
  //const [profileLink, setProfileLink] = useState();

  useEffect(() => {
    const profile = DirectoryService.retrieveLoggedProfile();
    console.log(profile.avatar);
    const url = profile.avatar;
    console.log(url);
    setPicture(url);
    //const profileLink = '/social/directory/user-details/' + profile.identifier;
    //setProfileLink(profileLink);
  }, [picture]);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        Quick Actions
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={'/app/holidays/create-form'}>
            New Holiday
          </Dropdown.Item>
          <Dropdown.Divider />
          {DirectoryService.isLoggedByHRAdmin() && (
            <>
              <Dropdown.Item as={Link} to={'/social/directory/user-details'}>
                New Employee
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={'/app/roles/role-form'}>
                New Job Vaccancy
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Item as={Link} to={'/app/annoucements/create-form'}>
            New Annocement
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={'/management-events/create-form'}>
            New Event
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default QuickActionsDropdown;
