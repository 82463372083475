import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FloatingLabel } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';
import IconAlert from 'components/common/IconAlert';
import { useParams } from 'react-router-dom';
import InvoiceService from './InvoiceService';
import vat_values from './data/vat.json';
import InvoicePaymentForm from './InvoicePaymentForm';
import InvoicePaymentsList from './InvoicePaymentsList';
import codes from './data/invoices-codes.json';
import PropTypes from 'prop-types';
/* This is a higher order component that
 *  inject a special prop   to our component.
 */
const formatDate = dateString => {
  var dateParts = dateString.split('/');
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};
const calculateVatTax = vatCode => {
  let vatTax = 'STANDARD_RATE' === vatCode ? 0.2 : 0;
  vatTax = 'REDUCED_RATE' === vatCode ? 0.05 : vatTax;
  vatTax = 'REDUCED_RATE_HHAA' === vatCode ? 0.125 : vatTax;

  return vatTax;
};
function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      identifier: null,
      date: null,
      dueDate: null,
      invoiceNumber: Math.floor(Math.random() * 1000),
      ref: 'Invoice ' + uuidv4(),
      contact: 'Manolo Fernandez',
      project: 'CodersDen UK',
      items: [],
      errorMessage: null,
      success: false,
      totalNet: 0,
      totalVatAmount: 0,
      vatAmount: [],
      showMakeAPaymentButton: false,
      showMakeAPaymentModal: false,
      showPayments: false,
      payments: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.updateState = this.updateState.bind(this);
    this.calculateTotals = this.calculateTotals.bind(this);
    this.handleShowMakeAPaymentModal =
      this.handleShowMakeAPaymentModal.bind(this);
    this.hideMakeAPaymentModal = this.hideMakeAPaymentModal.bind(this);
    this.refreshPayments = this.refreshPayments.bind(this);
  }
  handleShowMakeAPaymentModal() {
    this.setState({
      showMakeAPaymentModal: true,
      showMakeAPaymentButton: false
    });
  }
  hideMakeAPaymentModal() {
    this.setState({
      showMakeAPaymentModal: false,
      showMakeAPaymentButton: true
    });
    this.refreshPayments();
  }
  refreshPayments() {
    this.setState({ showPayments: false });
    console.log('refreshPayments');
    InvoiceService.retrieveAllPaymentsForInvoice(this.state.identifier).then(
      payments => {
        const showPayments = payments.length > 0 ? true : false;
        this.setState({ showPayments: showPayments, payments: payments });
      }
    );
  }
  componentDidMount() {
    const { identifier } = this.props.params;
    if (identifier !== undefined) {
      // TODO Add Date
      InvoiceService.retrieveInvoice(identifier).then(invoice => {
        console.log(invoice);
        const date =
          invoice.date !== null ? formatDate(invoice.date) : new Date();
        const dueDate =
          invoice.dueDate !== null ? formatDate(invoice.dueDate) : new Date();
        const showPayments = invoice.payments.length > 0 ? true : false;
        this.calculateTotals(invoice.items); // Calculate Totals
        this.setState({
          date: date,
          dueDate: dueDate,
          identifier: invoice.identifier,
          ref: invoice.ref,
          project: invoice.project,
          contact: invoice.contact,
          invoiceNumber: invoice.invoiceNumber,
          items: invoice.items,
          showMakeAPaymentButton: true,
          showPayments: showPayments,
          payments: invoice.payments
        });
      });
    } else {
      const date = new Date();
      this.setState({ date: date, dueDate: date });

      this.setState({
        items: [
          {
            nominalCode: '',
            lineRef: null,
            quantity: '1.00',
            amount: 0,
            vat: 0
          }
        ]
      });
    }
  }
  handleAddItem() {
    const newItem = { nominalCode: '', quantity: '1.00' };
    let array = this.state.items;
    array.push(newItem);

    this.setState({ items: array });
    console.log(this.state.items);
  }
  handleSubmit(e) {
    e.preventDefault();
    const body = JSON.stringify({
      date: this.state.date,
      dueDate: this.state.dueDate,
      invoiceNumber: this.state.invoiceNumber,
      ref: this.state.ref,
      contact: this.state.contact,
      project: this.state.project,
      items: this.state.items,
      payments: []
    });

    if (this.state.identifier === null) {
      const url =
        'http://localhost:8095/invoice/account/9c72efcc-fe0b-11ec-b939-0242ac120002';
      axios
        .post(url, body, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          this.setState({
            identifier: response.data.identifier,
            items: response.data.items,
            success: true
          });
        })
        .catch(error => {
          this.setState({ errorMessage: error.message });
          console.error('There was an error!', error);
        });
    } else {
      const url = 'http://localhost:8095/invoice/' + this.state.identifier;
      axios
        .put(url, body, { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
          this.setState({
            identifier: response.data.identifier,
            items: response.data.items,
            success: true
          });
        })
        .catch(error => {
          this.setState({ errorMessage: error.message });
          console.error('There was an error!', error);
        });
    }
  }

  calculateTotals(items) {
    const totalNet = items.reduce(
      (prev, curr) => prev + curr.amount * curr.quantity,
      0
    );

    items.map((element, index) => {
      const vatTax = calculateVatTax(element.vat);
      let vatAmount = vatTax * element.amount * element.quantity;
      if (vatTax > 0) {
        vatAmount =
          'gross' === element.amountType
            ? element.amount * element.quantity -
              (element.amount * element.quantity) / (1 + vatTax)
            : vatAmount;
        vatAmount = Math.round(vatAmount * 100) / 100;
      }
      items[index].vatAmount = vatAmount;
    });
    let totalVatAmount = items.reduce((prev, curr) => prev + curr.vatAmount, 0);
    totalVatAmount = Math.round(totalVatAmount * 100) / 100;
    this.setState({
      items: items,
      totalNet: totalNet,
      totalVatAmount: totalVatAmount
    });
  }
  updateState(event, index) {
    console.log('event: ' + event.target.id);
    console.log('event: ' + event.target.value);
    console.log('index: ' + index);
    const newArray = this.state.items.map((item, i) => {
      if (index === i) {
        return { ...item, [event.target.id]: event.target.value };
      } else {
        return item;
      }
    });
    this.calculateTotals(newArray);
    this.setState({ items: newArray });
  }
  handleChange(event) {
    let name = event.target.id;
    this.setState({ [name]: event.target.value });
  }
  render() {
    return (
      <>
        {this.state.success && (
          <IconAlert
            variant="success"
            dismissible
            onClose={() => this.setState({ success: false })}
          >
            <p className="mb-0">Invoice has been saved</p>
          </IconAlert>
        )}
        {this.state.showMakeAPaymentModal && (
          <InvoicePaymentForm
            invoiceIdentifier={this.state.identifier}
            showModal={this.state.showMakeAPaymentModal}
            hideModal={this.hideMakeAPaymentModal}
          />
        )}
        <Form onSubmit={this.handleSubmit} id="invoice-form">
          <Card>
            <Card.Header>
              <Row className="flex-between-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">Create New Invoice</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    variant="falcon-default"
                    className="me-2"
                    type="submit"
                  >
                    Save Draft
                  </Button>{' '}
                  {this.state.showMakeAPaymentButton && (
                    <Button
                      size="sm"
                      variant="falcon-primary"
                      onClick={() => this.handleShowMakeAPaymentModal()}
                    >
                      Make a Payment
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={2} className="px-1">
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    selected={this.state.date}
                    onChange={date => {
                      console.log(date);
                      console.log(moment());
                      this.setState({ date: date });
                    }}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Col sm={2}>
                  <Form.Label>Due Date</Form.Label>
                  <DatePicker
                    selected={this.state.dueDate}
                    onChange={dueDate => this.setState({ dueDate: dueDate })}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="px-1">
                  <FloatingLabel label="Ref">
                    <Form.Control
                      id="ref"
                      type="text"
                      required
                      value={this.state.ref}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-1">
                  <FloatingLabel label="Invoice Number">
                    <Form.Control
                      id="invoiceNumber"
                      type="text"
                      required
                      value={this.state.invoiceNumber}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-1">
                  <FloatingLabel label="Contact">
                    <Form.Control
                      id="contact"
                      type="text"
                      required
                      value={this.state.contact}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-1">
                  <FloatingLabel label="Project" className="mb-2">
                    <Form.Control
                      id="project"
                      type="text"
                      required
                      value={this.state.project}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <Row className="m-1">
                <Col>
                  <h4 className="fs-0 text-capitalize">Invoice Items</h4>
                </Col>
                <Col>
                  <Flex>
                    <Button
                      variant="falcon-success"
                      size="sm"
                      className="px-4 px-sm-5"
                      onClick={() => this.handleAddItem()}
                    >
                      Add Item
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {this.state.items.map((item, index) => (
                <Row className="mb-0" key={index}>
                  <Col sm={1} className="p-1">
                    <FloatingLabel label="Code">
                      <Form.Select
                        aria-label="Floating label select example"
                        id="nominalCode"
                        defaultValue={item.nominalCode}
                        onChange={event => this.updateState(event, index)}
                      >
                        {codes.map(group => (
                          <optgroup
                            key={group.groupName}
                            label={group.groupName}
                          >
                            {group.types.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col sm={3} className="p-1">
                    <FloatingLabel label="Line Ref">
                      <Form.Control
                        id="lineRef"
                        value={this.state.items[index].lineRef}
                        type="text"
                        required
                        onChange={event => this.updateState(event, index)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={1} className="p-1">
                    <FloatingLabel label="QTY">
                      <Form.Control
                        id="quantity"
                        value={this.state.items[index].quantity}
                        type="text"
                        required
                        onChange={event => this.updateState(event, index)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={2} className="p-1">
                    <FloatingLabel label="Amount">
                      <Form.Control
                        id="amount"
                        type="text"
                        value={this.state.items[index].amount}
                        required
                        onChange={event => this.updateState(event, index)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={1} className="p-1">
                    <Form.Check
                      className="mb-0"
                      type="radio"
                      label="Net"
                      id="amountType"
                      checked={this.state.items[index].amountType === 'net'}
                      value="net"
                      onChange={event => this.updateState(event, index)}
                    />
                    <Form.Check
                      type="radio"
                      label="Gross"
                      id="amountType"
                      value="gross"
                      checked={this.state.items[index].amountType === 'gross'}
                      onChange={event => this.updateState(event, index)}
                    />
                  </Col>
                  <Col sm={1} className="p-1">
                    <FloatingLabel label="VAT">
                      <Form.Select
                        aria-label="Floating label select example"
                        defaultValue={this.state.items[index].vat}
                        id="vat"
                        onChange={event => this.updateState(event, index)}
                      >
                        {vat_values.map(vat => (
                          <option key={vat.label} value={vat.value}>
                            {vat.label}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col className="p-1">
                    <FloatingLabel label="VAT">
                      <Form.Control
                        disabled="disabled"
                        id="vatAmount"
                        type="text"
                        value={this.state.items[index].vatAmount}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col className="p-1">
                    <FloatingLabel label="Total">
                      <Form.Control
                        disabled="disabled"
                        id="vatAmount"
                        type="text"
                        value={
                          this.state.items[index].vatAmount +
                          this.state.items[index].amount *
                            this.state.items[index].quantity
                        }
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col sm={2} className="px-1"></Col>
                <Col sm={3} className="px-1"></Col>
                <Col sm={3} className="p-1">
                  <FloatingLabel label="Total Net">
                    <Form.Control
                      disabled="disabled"
                      id="totalNet"
                      type="text"
                      value={this.state.totalNet}
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={1} className="px-1"></Col>
                <Col className="p-1">
                  <FloatingLabel label="Total VAT">
                    <Form.Control
                      disabled="disabled"
                      id="totalVAT"
                      type="text"
                      value={this.state.totalVatAmount}
                    />
                  </FloatingLabel>
                </Col>
                <Col className="p-1">
                  <FloatingLabel label="Total">
                    <Form.Control
                      disabled="disabled"
                      id="total"
                      type="text"
                      value={this.state.totalNet + this.state.totalVatAmount}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Flex>
                <Button variant="falcon-success" type="submit">
                  Approve Invoice
                </Button>
              </Flex>
            </Card.Body>
          </Card>
        </Form>
        {this.state.showPayments && (
          <InvoicePaymentsList
            paymentsList={this.state.payments}
            invoiceIdentifier={this.state.identifier}
          ></InvoicePaymentsList>
        )}
      </>
    );
  }
}

InvoiceForm.propTypes = {
  params: PropTypes.shape({
    identifier: PropTypes.any
  })
};
const HOCInvoiceForm = withRouter(InvoiceForm);
export default HOCInvoiceForm;
