const jsonSchema = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    showInList: true
  },
  {
    name: 'type',
    label: 'Type',
    type: 'multiselect',
    showInList: true,
    isMulti: true,
    required: true
  },

  {
    name: 'members',
    label: 'Members',
    type: 'multiselect',
    showInList: false,
    isMulti: true,
    required: true
  },
  {
    name: 'description',
    label: 'Description',
    type: 'editor',
    required: true,
    showInList: true
  }

  // Add more fields as needed
];
export default jsonSchema;
