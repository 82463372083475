import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import AnnoucementService from './AnnoucementService';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { useNavigate } from 'react-router-dom';
import AnnoucementItem from './AnnoucementItem';
import { Link } from 'react-router-dom';
import DirectoryService from '../directory/DirectoryService';
const AnnoucementList = () => {
  const [data, setData] = useState();
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [error, setError] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    AnnoucementService.retrieveAllAnnoucementsByProfile()
      .then(response => {
        //TODO improve this
        const updatedData = response.map(d => {
          delete d.audience;
          return d;
        });
        setData(updatedData);
        setNoDataToShow(!updatedData.length > 0);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);

  const buttonsConf =
    DirectoryService.isLoggedByHRAdmin() === true
      ? [
          {
            name: 'Create a new Annoucement',
            type: 'submit',
            variant: 'info',
            onClick: () => {
              console.log('Create a new Annoucement');
              navigate('/app/annoucements/create-form');
            }
          }
        ]
      : [];
  const headerGenerated = useHeaderGenerator('Annoucements', ' ', buttonsConf);
  return (
    <>
      {error}
      {headerGenerated}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Annoucement to show. Let's create a new one!
        </Alert>
      )}
      {data && (
        <Card>
          <Card.Header className="bg-light">
            <Row className="flex-between-center">
              <Col sm="auto">
                <h5 className="mb-1 mb-md-0">Your annoucements</h5>
              </Col>
              <Col sm="auto" className="fs--1">
                <Link to="#!" className="font-sans-serif ms-2 ms-sm-3">
                  Mark all as read
                </Link>
                <div className="font-sans-serif ms-2 ms-sm-3"></div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            {data.map(annoucement => (
              <AnnoucementItem {...annoucement} key={annoucement.identifier} />
            ))}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default AnnoucementList;
