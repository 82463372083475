const SupportTicketService = {
  async saveSupportTicket(formData) {
    try {
      let url = '/api/support/ticket';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;

      if (formData.identifier !== undefined) {
        url = '/api/support/ticket/' + formData.identifier;
        method = 'PUT';
      }
      const type = formData.type.value;
      const updatedFormData = {
        ...formData,
        accountIdentifier: accountIdentifier,
        profileIdentifier: profileLogged.identifier,
        type: type
      };
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });
      if (response.ok) {
        const newSupportTicket = await response.json();
        console.log('Support Ticket has been updated:', newSupportTicket);
      }
    } catch (error) {
      console.log('Error saving Support Ticket', error);
    }
  },
  async retrieveAllSupportTicketByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/support/ticket/account/' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching SupportTicket', error);
      throw error;
    }
  },
  async retrieveSupportTicket(identifier) {
    try {
      const response = await fetch('/api/support/ticket/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching SupportTicket', error);
      throw error;
    }
  },
  async deleteSupportTicket(identifier) {
    try {
      let url = '/api/support/ticket/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newSupportTicket = await response.json();
        console.log('Ticket has been deleted:', newSupportTicket);
      }
    } catch (error) {
      console.log('Error deleting Support Ticket', error);
    }
  }
};
export default SupportTicketService;
