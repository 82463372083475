import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import HolidayService from './HolidayService';
import DirectoryService from '../directory/DirectoryService';
import schema from './jsonSchema';
import { useNavigate } from 'react-router-dom';

const jsonSchema = schema;

const transformDate = inputDate => {
  const date = new Date(inputDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  //const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

const assignValueToJsonSchema = (field, types) => {
  if (field.name === 'type') {
    return {
      ...field,
      options: types
    };
  }
  return field;
};

const mergedJsonSchema = (jsonSchema, types) => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f => assignValueToJsonSchema(f, types))
      };
    } else {
      return assignValueToJsonSchema(field, types);
    }
  });
};
const HolidayForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [types, setTypes] = useState();
  const [manager, setManager] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const manager = DirectoryService.retrieveManagerForThisUser();
    console.log(manager);
    setManager(manager);
    if (undefined !== identifier) {
      HolidayService.retrieveHoliday(identifier)
        .then(data => {
          const type = [{ label: data.type, value: data.type }];
          const updatedData = {
            ...data,
            start: transformDate(data.start),
            end: transformDate(data.end),
            type: type
          };
          setData(updatedData);
          console.log(updatedData);
        })
        .catch(error => console.log(error));
    }
    const typeValues = [
      {
        label: 'Annual Leave',
        value: 'Annual Leave'
      },
      {
        label: 'Unwell',
        value: 'Unwell'
      },
      {
        label: 'Bank Holiday',
        value: 'Bank Holiday'
      },
      {
        label: 'Other',
        value: 'Other'
      }
    ];
    setTypes(typeValues);
  }, []);

  const saveAsDraft = () => {
    const updatedData = { ...data, draft: true };
    handleHolidayFormSubmit(updatedData);
  };

  const handleHolidayFormSubmit = formData => {
    const profileLogged = JSON.parse(localStorage.getItem('profile'));
    const { identifier } = profileLogged;
    formData.authorizedBy =
      undefined === manager ? identifier : manager.identifier;
    HolidayService.saveHoliday(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => {
        setModalErrorShow(true);
        setErrorMessage(error.message);
      });
  };

  const buttonsConf = [
    {
      name: 'Save Holiday',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Draft');
      }
    },
    {
      name: 'Save Draft',
      type: 'button',
      variant: 'success',
      onClick: () => {
        saveAsDraft();
      }
    }
  ];
  let headerTitle =
    undefined != identifier ? 'Update holiday' : 'Create Holiday';
  headerTitle =
    data !== undefined && data.draft ? headerTitle + ' (draft)' : headerTitle;

  const headerGenerated = useHeaderGenerator(headerTitle, '<br />', []);
  const setModalShowAndClose = modalShow => {
    setModalShow(modalShow);
    navigate('/app/holidays');
  };
  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Holiday has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShowAndClose}
        />
      )}
      {modalErrorShow && (
        <FormModal
          message={errorMessage}
          modalShow={modalErrorShow}
          setModalShow={setModalErrorShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleHolidayFormSubmit}
              types={types}
              manager={manager}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({
  data,
  buttonsConf,
  onSubmit,
  types,
  manager
}) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);

  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, types),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      {!DirectoryService.isLoggedByHRAdmin() && (
        <Row>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label className="fs-0">Approver</Form.Label>
            <Form.Control
              plaintext
              defaultValue={
                (manager?.firstName || '') + ' ' + (manager?.lastName || '')
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="label">
            <Form.Label className="fs-0">Send to</Form.Label>
            <Form.Control
              plaintext
              readOnly
              defaultValue={manager?.email || ''}
            />
          </Form.Group>
        </Row>
      )}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  manager: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  onSubmit: PropTypes.any,
  types: PropTypes.any
};
export default HolidayForm;
