const jsonSchema = [
  {
    type: 'group',
    fields: [
      {
        name: 'start',
        label: 'Start Date',
        type: 'date',
        pattern: 'dd/MM/yyyy',
        required: true,
        showInList: true
      },
      {
        name: 'end',
        label: 'End Date',
        pattern: 'dd/MM/yyyy',
        type: 'date',
        showInList: true,
        required: true
      }
    ]
  },
  {
    name: 'type',
    label: 'Type',
    type: 'multiselect',
    required: true,
    showInList: true,
    isMulti: false
  },
  {
    type: 'group',
    fields: [
      {
        name: 'includeSaturday',
        label: 'Include Saturday',
        type: 'checkbox',
        showInList: false
      },
      {
        name: 'includeSunday',
        label: 'Include Sunday',
        type: 'checkbox',
        showInList: false
      }
    ]
  },
  {
    type: 'group',
    fields: [
      {
        name: 'halfDayStart',
        label: 'Half Day Start',
        type: 'checkbox',
        showInList: false
      },
      {
        name: 'halfDayEnd',
        label: 'Half Day End',
        type: 'checkbox',
        showInList: false
      }
    ]
  },

  {
    name: 'comments',
    label: 'Comments',
    type: 'editor',
    required: false,
    showInList: true
  }
  // Add more fields as needed
];
export default jsonSchema;
