import React, { useState, useEffect } from 'react';
import LeadService from './LeadService';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../common/AdvanceList';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';

const LeadList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const [leadSources, setLeadSources] = useState();
  const [exportToExcelData, setExportToExcelData] = useState([]);
  const navigate = useNavigate();

  const transformData = (attr, item) => {
    console.log(attr);
    if (attr == 'email') {
      const url = '/app/leads/' + item['identifier'];
      return <Link to={url}>{item[attr]}</Link>;
    }
    return item[attr];
  };
  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    LeadService.retrieveAllLeadByProfile()
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor),
            transformData
          )
        );
        const exportToExcelData = data.map(x => ({
          identifier: x.identifier,
          'first name': x.firstName,
          'last name': x.lastName,
          email: x.email,
          phone: x.phoneNumber,
          location: x.location,
          source: x.leadSource,
          comments: x.comments
        }));
        setExportToExcelData(exportToExcelData);

        setNoDataToShow(!data.length > 0);
      })
      .catch(error => setError({ error: error.message }));

    const sources = [
      {
        label: 'Website traffic',
        value: 'Website traffic'
      },
      {
        label: 'Email marketing',
        value: 'Email marketing'
      },
      {
        label: 'Content marketing',
        value: 'Content marketing'
      },
      {
        label: 'Social media',
        value: 'Social media'
      },

      {
        label: 'Trade shows and events',
        value: 'Trade shows and events'
      },
      {
        label: 'Partnerships and referrals',
        value: 'Partnerships and referrals'
      },
      {
        label: 'Cold calling',
        value: 'Cold calling'
      },
      {
        label: 'Direct mail',
        value: 'Direct mail'
      }
    ];
    setLeadSources(sources);
    console.log(leadSources);
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new Lead',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Lead');
        navigate('/app/leads/create-form');
      }
    },
    {
      name: 'Export to Excel',
      type: 'button',
      variant: 'outline-success',
      onClick: () => {
        const data = exportToExcelData;
        const name = 'Leads';
        let wb = utils.book_new();
        let ws = utils.json_to_sheet(data);
        utils.book_append_sheet(wb, ws, name);
        const fileName = name + '.xlsx';
        writeFile(wb, fileName);
      }
    }
  ];
  const leadsDescription =
    'Capture and nurture potential candidates with our powerful Leads Management module. Streamline the process of tracking and managing candidate leads from various sources, ensuring that no opportunity is missed. This module allows you to categorize leads, monitor their progress through the recruitment pipeline, and maintain clear communication with prospective talent. By integrating seamlessly with your recruitment workflows, our Leads Management module helps you build a strong talent pipeline and make data-driven decisions to enhance your hiring strategy.';
  const headerGenerated = useHeaderGenerator(
    'Leads Managment',
    leadsDescription,
    buttonsConf
  );
  return (
    <>
      {error}
      {headerGenerated}
      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any Lead to show. Let's create a new one!
        </Alert>
      )}
      {data && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

export default LeadList;
