import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext from 'context/Context';
import DirectoryService from 'components/app/directory/DirectoryService';
import { Link } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';
const UsersCardDropdown = ({
  btnRevealClass,
  drop,
  children,
  users,
  icon = 'ellipsis-h'
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const exportToExcel = () => {
    console.log(users);
    const exportToExcelData = users.map(x => ({
      identifier: x.identifier,
      email: x.email
    }));
    const data = exportToExcelData;
    const name = 'Users';
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, name);
    const fileName = name + '.xlsx';
    writeFile(wb, fileName);
  };
  return (
    <Dropdown
      className="font-sans-serif btn-reveal-trigger"
      align={isRTL ? 'start' : 'end'}
      drop={drop}
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
      >
        <FontAwesomeIcon icon={icon} className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            <Dropdown.Item as={Link} to={'social/directory'}>
              View more
            </Dropdown.Item>
            {DirectoryService.isLoggedByHRAdmin() && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => exportToExcel()}>
                  Export
                </Dropdown.Item>
              </>
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

UsersCardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  children: PropTypes.node,
  drop: PropTypes.string,
  icon: PropTypes.string,
  users: PropTypes.shape({
    map: PropTypes.func
  })
};

export default UsersCardDropdown;
