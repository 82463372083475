import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import DirectoryService from 'components/app/directory/DirectoryService';
import * as uuid from 'uuid';
import cloudDownload from 'assets/img/icons/delete-alt-1.svg';

const ToDoListItem = ({
  item,
  index,
  length,
  handleChange,
  handleInsert,
  handleRemove
}) => {
  const handleKeyDown = event => {
    if (event.key == 'Enter') {
      const task = event.target.value;
      const id = uuid.v4();
      DirectoryService.createToDoItem(id, task).then(() =>
        handleInsert(id, task)
      );
    }
  };
  return (
    <Flex
      justifyContent="between"
      className={classNames(
        'hover-actions-trigger btn-reveal-trigger px-card hover-bg-100 border-200 border-top',
        { 'border-bottom': index === length - 1 }
      )}
      alignItems="center"
    >
      <Form.Check
        className="py-2 my-2 d-flex align-items-center"
        id={`todoItem-${index}`}
      >
        <Form.Check.Input
          type="checkbox"
          defaultChecked={item.completed}
          onChange={e => handleChange(item.id, e.target.checked)}
          className={classNames(
            `mt-0 rounded-circle form-check-input-${item.color}`,
            {
              'form-check-line-through': item.completed
            }
          )}
        />
        {item.task !== '' ? (
          <Form.Check.Label className="flex-1 mb-0 ms-3">
            {item.task}
          </Form.Check.Label>
        ) : null}
      </Form.Check>
      {item.task == '' ? (
        <Form.Control
          type="text"
          onKeyDown={handleKeyDown}
          className="mb-1"
        ></Form.Control>
      ) : null}
      <div className="hover-actions end-0 top-50 translate-middle-y">
        <OverlayTrigger overlay={<Tooltip id="nextTooltip">Delete</Tooltip>}>
          <Button
            variant="light"
            size="sm"
            className="border-300 me-1 text-600"
            as={'a'}
            onClick={() => handleRemove(index)}
          >
            <img src={cloudDownload} alt="Delete" width={15} />
          </Button>
        </OverlayTrigger>
      </div>
    </Flex>
  );
};

const ToDoList = () => {
  const [todoItems, setTodoItems] = useState([]);

  useEffect(() => {
    DirectoryService.retrieveToDoList()
      .then(list =>
        list.map(item => ({
          id: item.identifier,
          task: item.title,
          completed: item.done,
          color: 'primary'
        }))
      )
      .then(setTodoItems);
  }, []);

  const handleChange = (id, completed) => {
    DirectoryService.completeItem(id).then(() => {
      const updatedTodos = todoItems.map(item =>
        item.id === id ? { ...item, completed } : item
      );
      setTodoItems(updatedTodos);
    });
  };
  const handleNewItem = () => {
    const newItem = { id: '', task: '', color: 'primary' };
    setTodoItems(todoItems => [...todoItems, newItem]);
  };
  const handleRemove = indexToRemove => {
    const itemToRemove = todoItems[indexToRemove];
    let itemsUpdated = todoItems.filter(
      (value, index) => index !== indexToRemove
    );
    setTodoItems(itemsUpdated);
    console.log(itemToRemove);
    if (itemToRemove.id !== undefined) {
      DirectoryService.removeToDoItem(itemToRemove.id)
        .then(response => console.log(response))
        .catch(error => console.log(error));
    }
  };
  const handleInsert = (_id, _task) => {
    const newItem = { id: _id, task: _task, color: 'primary' };
    setTodoItems(todoItems => [...todoItems.slice(0, -1), newItem]);
  };
  return (
    <>
      <Card className="h-100">
        <Card.Header>
          <h6 className="fs-0 text-capitalize">ToDo List</h6>
        </Card.Header>
        <Card.Body className="p-0 overflow-hidden">
          {todoItems.map((item, index) => (
            <ToDoListItem
              key={item.id}
              item={item}
              index={index}
              length={todoItems.length}
              setTodoItems={setTodoItems}
              handleChange={handleChange}
              handleInsert={handleInsert}
              handleRemove={handleRemove}
            />
          ))}
        </Card.Body>
        <Card.Footer className="p-0 bg-light">
          <Button
            as={Link}
            variant="link"
            size="sm"
            to="#!"
            id="add-new-task-button"
            onClick={handleNewItem}
            className="w-100 py-2"
          >
            <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
            Add New Task
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

ToDoListItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleInsert: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    color: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    task: PropTypes.string.isRequired
  }),
  length: PropTypes.number.isRequired
};

export default ToDoList;
