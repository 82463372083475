import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { Doughnut } from 'react-chartjs-2';
import { getColor } from 'helpers/utils';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import NewPositionsList from './NewPositionsList';
import Stats from '../../dashboards/analytics/stats/Stats';
import CrmStats from '../../dashboards/crm/CrmStats';
const options = {
  tooltip: chartJsDefaultTooltip(),
  rotation: -90,
  circumference: '180',
  cutout: '80%',
  plugins: {
    legend: {
      display: false
    },
    tooltip: chartJsDefaultTooltip()
  }
};
const data = {
  labels: ['Falcon', 'Sparrow'],
  datasets: [
    {
      data: [50, 88],
      backgroundColor: [getColor('primary'), getColor('300')],
      borderColor: [getColor('primary'), getColor('300')]
    }
  ]
};

class BudgetLandingPage extends React.Component {
  render() {
    return (
      <>
        <Row className="g-3 mb-3">
          <Col>
            <CrmStats />
            <br></br>
            <Stats />
          </Col>
          <Col xxl={6} xl={12}>
            <Row className="g-3">
              <Col xs={12}>
                <Card className="h-md-100">
                  <Card.Header className="pb-0">
                    <h4 className="mb-0 mt-2">2022 Budget</h4>
                  </Card.Header>

                  <Card.Body
                    as={Flex}
                    alignItems="end"
                    justifyContent="between"
                  >
                    <div>
                      <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">
                        34.6%
                      </h2>
                      <SoftBadge pill bg="warning" className="me-2 fs--2">
                        <FontAwesomeIcon icon="caret-up" className="me-1" />
                        3.5%
                      </SoftBadge>
                    </div>
                    <div>
                      <Doughnut
                        data={data}
                        options={options}
                        width="300"
                        className="my-n5"
                      />
                      <p className="mb-0 mt-4 text-center fs--2 text-500">
                        Target: <span className="text-800"> 55% </span>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col>
            <NewPositionsList />
          </Col>
        </Row>
      </>
    );
  }
}

export default BudgetLandingPage;
