import React from 'react';
import { Table } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import ActionButton from 'components/common/ActionButton';
function useTableGenerator(data, jsonSchema, actionButtons) {
  const columns = jsonSchema.map(field => {
    if (field.type === 'group') {
      return field.fields.map(f => f); // Use map instead of forEach
    } else {
      return field;
    }
  });

  return (
    <Card className="mb-3">
      <Card.Body>
        <Table striped responsive>
          <thead>
            <tr>
              {columns.map((c, index) => (
                <th key={index} scope="col">
                  {c.label}
                </th>
              ))}
              {actionButtons && (
                <th className={'text-end'} scope="col">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((d, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((c, colIndex) => (
                  <td key={colIndex}>{d[c.name]}</td>
                ))}
                {actionButtons && (
                  <td className="text-end">
                    {actionButtons.map((b, buttonIndex) => (
                      <ActionButton
                        key={buttonIndex}
                        icon={b.icon}
                        title={b.title}
                        variant={b.variant}
                        className={b.className}
                        onClick={identifier => {
                          console.log(identifier);
                          b.onClick(d['identifier']);
                        }}
                      />
                    ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default useTableGenerator;
