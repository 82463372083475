import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import BrightStaffLogin from 'components/app/common/BrightStaffLogin';
import LoginService from 'components/app/common/LoginService';
import { useState } from 'react';
import CompleteRegistration from './components/app/common/CompleteRegistration';
import withErrorHandling from './components/app/common/functions/withErrorHandling';

const App = () => {
  const [showGetStartedForm, setShowGetStartedForm] = useState(false);
  const isUserAuthorized = () => {
    console.log(LoginService.isUserAuthenticated());
    return LoginService.isUserAuthenticated();
  };
  const handleShowRegistrationClick = () => {
    setShowGetStartedForm(!showGetStartedForm);
    window.location.href = 'https://codersden.uk/hr-software-buy-it-now/';
  };

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/authentication/complete-registration"
          exact
          element={<CompleteRegistration />}
        />
        <Route
          path="/"
          element={
            isUserAuthorized() ? (
              <Layout />
            ) : (
              <BrightStaffLogin
                showRegistration={handleShowRegistrationClick}
              />
            )
          }
        />
        {/* All other routes render the same Layout component */}
        <Route path="/*" element={<Layout />} />
      </Routes>
    </Router>
  );
};

export default withErrorHandling(App);
