import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import schema from './jsonSchema';
import useDetailsGenerator from '../common/functions/useDetailsGenerator';
import DirectoryService from '../directory/DirectoryService';

const CustomDetails = () => {
  const { identifier } = useParams();
  const jsonSchema = schema;
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    DirectoryService.retrieveProfile(identifier)
      .then(data => {
        setData(data);
        setShowDetails(true);
      })
      .catch(error => console.log(error));
  }, [identifier]);

  return (
    <>
      {showDetails && (
        <Card.Body>{useDetailsGenerator(data, jsonSchema)}</Card.Body>
      )}
    </>
  );
};

export default CustomDetails;
