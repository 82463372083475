import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import SupportTicketService from './SupportTicketService';
import schema from './jsonSchema';
import DOMPurify from 'dompurify';

const SupportTicketDetails = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [event, setEvent] = useState();

  const showValue = attributeName => {
    if (attributeName === 'body') {
      const sanitizedHtml = DOMPurify.sanitize(event['body']);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }
    return event[attributeName];
  };

  useEffect(() => {
    SupportTicketService.retrieveSupportTicket(identifier)
      .then(data => {
        setEvent(data);
      })
      .catch(error => {
        console.error('Error retrieving SupportTicket', error);
      });
  }, []);

  const buttonsConf = [
    {
      name: 'Edit SupportTicket',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this event');
        navigate('/app/support/tickets/' + identifier + '/edit');
      }
    }
  ];

  const jsonSchema = schema;
  const headerGenerated = useHeaderGenerator('SupportTicket', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'SupportTicket has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {event && (
        <>
          <Card>
            <Card.Body>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">{f.label + ': '}</p>
                      </Col>
                      <Col>{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col>{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default SupportTicketDetails;
