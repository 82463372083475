import React, { Component } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

export function withRouter(Child) {
  return props => {
    const params = useParams();
    return <Child {...props} params={params} />;
  };
}

class DirectoryProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {}
    };
  }
  componentDidMount() {
    const { identifier } = this.props.params;
    console.log('Identifier: ' + identifier);
    fetch('/api/profile/' + identifier)
      .then(response => response.json())
      .then(data => {
        this.setState({
          ...this.state, // Not required but just a heads up on using mutation
          profile: data
        });
      })
      .catch(error => {
        console.error(error);
      });
  }
  render() {
    return (
      <>
        <table>
          <tbody>
            <tr>
              <td>id:</td>
              <td>{this.state.profile.identifier}</td>
            </tr>
            <tr>
              <td>First Name:</td>
              <td>
                <b>{this.state.profile.firstName}</b>
              </td>
            </tr>
            <tr>
              <td>Last Name:</td>
              <td>
                <b>{this.state.profile.lastName}</b>
              </td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>
                <b>{this.state.profile.email}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

DirectoryProfile.propTypes = {
  params: PropTypes.shape({
    identifier: PropTypes.string.isRequired
  })
};

export default withRouter(DirectoryProfile);
