import React from 'react';
import { Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import expenses from 'data/expenses.js';
class ExpensesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: []
    };
  }
  componentDidMount() {
    const columns = [
      {
        accessor: 'date',
        Header: 'Date'
      },
      {
        accessor: 'ref',
        Header: 'Reference'
      },
      {
        accessor: 'employee',
        Header: 'employee'
      },
      {
        accessor: 'amount',
        Header: 'Amount'
      },
      {
        accessor: 'outstanding',
        Header: 'Outstanding',
        Cell: rowData => {
          const { outstanding } = rowData.row.original;
          // const { amount } = rowData.row.original;
          const status = outstanding === '£0' ? 'paid' : 'o/s';
          const statusType = status === 'paid' ? 'success' : 'warning';
          const statusIcon = status === 'paid' ? 'check' : 'stream';
          return (
            <>
              {outstanding + ' '}
              <SoftBadge pill bg={statusType}>
                {status}
                <FontAwesomeIcon icon={statusIcon} className="ms-2" />
              </SoftBadge>
            </>
          );
        }
      }
    ];
    this.setState({ columns: columns, data: expenses });
  }
  render() {
    return (
      <>
        <Card>
          <Card.Header></Card.Header>
          <Card.Body>
            <AdvanceTableWrapper
              columns={this.state.columns}
              data={this.state.data}
              sortable
              pagination
              perPage={20}
            >
              <AdvanceTable
                table
                headerClassName="bg-100 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={this.state.data.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ExpensesList;
