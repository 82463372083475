import React, { useState, useEffect } from 'react';
import SalaryLevelService from './SalaryLevelService';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../../common/functions/filterFieldsForList';
import filterDataForList from '../../common/functions/filterDataForList';
import schema from './jsonSchema';
import AdvanceList from '../../common/AdvanceList';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from '../../common/functions/useHeaderGenerator';

const SalaryLevelList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const jsonSchema = schema;
    console.log(error);
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    SalaryLevelService.retrieveAllSalaryLevelsByAccount()
      .then(data => {
        setData(
          filterDataForList(
            data,
            columns.map(c => c.accessor)
          )
        );
        setNoDataToShow(!data.length > 0);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);
  const buttonsConf = [
    {
      name: 'Create a new SalaryLevel',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a Salary Level');
        navigate('/app/roles/salarylevels/create-form');
      }
    },
    {
      name: 'Save Draft',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Save Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator(
    'Create a Salary Level',
    ' ',
    buttonsConf
  );
  return (
    <>
      {headerGenerated}

      {noDataToShow && (
        <Alert key="warning" variant="warning">
          There's not any SalaryLevel to show. Let's create a new one!
        </Alert>
      )}
      {data && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

export default SalaryLevelList;
