import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { Form, Button } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import UploadAvatar from '../UploadAvatar';
import DateInput from './DateInput';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DirectoryService from '../DirectoryService';
import DemoService from 'components/app/common/functions/DemoService';
export function withRouter(Child) {
  return props => {
    const params = useParams();
    return <Child {...props} params={params} />;
  };
}

class Personal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier:
        this.props.profile !== null ? this.props.profile.identifier : null,
      title: this.props.profile.title,
      firstName: this.props.profile.firstName,
      lastName: this.props.profile.lastName,
      knownAs: this.props.profile.knownAs,
      gender: this.props.profile.gender,
      preferredPronoun: this.props.profile.preferredPronoun,
      maritalStatus: this.props.profile.maritalStaus,
      address: this.props.profile.address,
      employeeNumber: this.props.profile.employeeNumber,
      email: this.props.profile.email,
      personalEmail: this.props.profile.personalEmail,
      dob: this.props.profile.dob,
      personalMobile: this.props.profile.personalMobile,
      validated: false,
      userAvatar: this.props.profile.userAvatar,
      roles: this.props.profile.roles,
      rolesValues: [
        { value: 'HR-ADMIN', label: 'HR-ADMIN' },
        { value: 'HR-USER', label: 'HR-USER' }
      ]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setUserAvatar = this.setUserAvatar.bind(this);
  }
  componentDidMount() {
    const roles = this.state.roles.map(v => ({ label: v.key, value: v.key }));
    this.setState({ roles: roles });
  }
  setUserAvatar(userAvatar) {
    this.setState({ userAvatar: userAvatar });
  }
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;

    this.setState({ validated: true });
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (form.checkValidity() === true) {
      const updatedProfile = {
        identifier: this.state.identifier,
        title: this.state.title,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        knownAs: this.state.knownAs,
        gender: this.state.gender,
        preferredPronoun: this.state.preferredPronoun,
        maritalStatus: this.state.maritalStaus,
        address: this.state.address,
        employeeNumber: this.state.employeeNumber,
        email: this.state.email,
        personalEmail: this.state.personalEmail,
        dob: this.state.dob,
        personalMobile: this.state.personalMobile,
        roles: this.state.roles.map(value => ({ key: value.value }))
      };
      this.props.updateProfile(updatedProfile, this.state.userAvatar);
    }
  }

  handleChange(e) {
    e.preventDefault();

    const { id, value } = e.target;
    console.log(id);
    console.log(value);
    this.setState({
      [id]: value
    });
  }
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <h5 className="fs-0 mb-3">
              Personal - {this.state.firstName + ' ' + this.state.lastName}
            </h5>
          </Card.Header>

          <Form
            className="mb-2"
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            <Card.Body>
              <Row className="g-2">
                <Col md>
                  <Card>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Title"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="title"
                        type="text"
                        required
                        value={this.state.title}
                        onSubmit={this.handleSubmit}
                        onChange={e => this.handleChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="First Name"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="firstName"
                        type="text"
                        required
                        value={this.state.firstName}
                        onChange={e => this.handleChange(e)}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Last Name"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="lastName"
                        type="text"
                        required
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Known As"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="knownAs"
                        type="text"
                        value={this.state.knownAs}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Gender"
                      className="mb-2"
                    >
                      <Form.Select
                        size="sm"
                        id="gender"
                        value={this.state.gender}
                        onChange={this.handleChange}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Date of Birth"
                      className="mb-2"
                    >
                      <DateInput
                        id="dob"
                        value={this.state.dob}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Marital status"
                      className="mb-2"
                    >
                      <Form.Select
                        size="sm"
                        id="maritalStatus"
                        value={this.state.maritalStatus}
                        onChange={this.handleChange}
                      >
                        <option value="7">Single</option>
                        <option value="1">Civil Partnership</option>
                        <option value="2">Co-Habiting</option>
                        <option value="3">Divorced</option>
                        <option value="4">Engaged</option>
                        <option value="5">Married</option>
                        <option value="6">Separated</option>
                        <option value="8">Undefined</option>
                        <option value="9">Widowed</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingSelect"
                      label="Preferred Pronoun"
                      className="mb-2"
                    >
                      <Form.Select
                        size="sm"
                        id="preferredPronoun"
                        value={this.state.preferredPronoun}
                        onChange={this.handleChange}
                      >
                        <option value="he/him">He/Him</option>
                        <option value="she/her">She/Her</option>
                        <option value="they/them">They/Them</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="address"
                      label="Address"
                      className="mb-2"
                    >
                      <Form.Control
                        value={this.state.address}
                        onChange={this.handleChange}
                        as="textarea"
                        placeholder="Address"
                        style={{ height: '150px' }}
                      />
                    </FloatingLabel>
                  </Card>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Employee Number"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="employeeNumber"
                      type="text"
                      required
                      value={this.state.employeeNumber}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="email"
                      type="email"
                      required
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Work Phone"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="workPhone"
                      type="text"
                      value={this.state.workPhone}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Work Extn"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="workExtn"
                      type="text"
                      value={this.state.workExtn}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Work Mobile"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="workMobile"
                      type="text"
                      value={this.state.workMobile}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Personal Email"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="personalEmail"
                      type="email"
                      required
                      value={this.state.personalEmail}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Personal Mobile"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="personalMobile"
                      type="text"
                      required
                      value={this.state.personalMobile}
                      onChange={this.handleChange}
                      maxLength={20} // Limit input to 10 characters
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Home Phone"
                    className="mb-2"
                  >
                    <Form.Control
                      size="sm"
                      id="homePhone"
                      type="text"
                      value={this.state.homePhone}
                      onChange={this.handleChange}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row className="g-2">
                <Col md>
                  <br></br>
                  <Card>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Emergency Contact"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="emergencyContact"
                        type="text"
                        value={this.state.emergencyContact}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Emergency Telephone"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="emergencyTelephone"
                        type="text"
                        value={this.state.emergencyTelephone}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="emergencyAddress"
                      label="Emergency Address"
                      className="mb-2"
                    >
                      <Form.Control
                        value={this.state.emergencyAddress}
                        onChange={this.handleChange}
                        as="textarea"
                        placeholder="Address"
                        style={{ height: '150px' }}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Relationship To you"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="relationshipToYou"
                        type="text"
                        value={this.state.relationshipToYou}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <Form.Group className="mb-3" controlId="roles">
                      <Form.Label className="fss-0">{'Roles'}</Form.Label>
                      <Select
                        isDisabled={!DirectoryService.isLoggedByHRAdmin()}
                        closeMenuOnSelect={false}
                        id="roles"
                        required={true}
                        options={this.state.rolesValues}
                        placeholder="Select..."
                        isMulti={true}
                        classNamePrefix="react-select"
                        value={this.state.roles || ''}
                        onChange={value => {
                          this.setState({ roles: value });
                        }}
                        className={
                          this.state.roles.length > 0
                            ? 'is-valid'
                            : 'is-invalid'
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {'This field is required'}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Card>
                </Col>
                <Col md>
                  <br></br>
                  <Card>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Ethnic Origin"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="ethnicOrigin"
                        type="text"
                        value={this.state.ethnicOrigin}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="medicalDetails"
                      label="Medical Details"
                      className="mb-2"
                    >
                      <Form.Control
                        value={this.state.medicalDetails}
                        onChange={this.handleChange}
                        as="textarea"
                        placeholder="Medical Details"
                        style={{ height: '150px' }}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Nationality"
                      className="mb-2"
                    >
                      <Form.Control
                        size="sm"
                        id="nationality"
                        type="text"
                        value={this.state.nationality}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <UploadAvatar
                      setUserAvatarFile={this.setUserAvatar}
                      userFile={this.state.userAvatar}
                    ></UploadAvatar>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {DirectoryService.isLoggedByHRAdmin() &&
                DemoService.isAValidIdentifier(this.state.identifier) && (
                  <Button variant="info" className="me-2" type="submit">
                    Save Profile
                  </Button>
                )}
            </Card.Footer>
          </Form>
        </Card>
      </>
    );
  }
}

Personal.propTypes = {
  profile: PropTypes.shape({
    address: PropTypes.any,
    dob: PropTypes.any,
    email: PropTypes.any,
    employeeNumber: PropTypes.any,
    firstName: PropTypes.any,
    gender: PropTypes.any,
    identifier: PropTypes.any,
    knownAs: PropTypes.any,
    lastName: PropTypes.any,
    maritalStaus: PropTypes.any,
    personalEmail: PropTypes.any,
    personalMobile: PropTypes.any,
    preferredPronoun: PropTypes.any,
    roles: PropTypes.any,
    title: PropTypes.any,
    userAvatar: PropTypes.any
  }),
  updateProfile: PropTypes.func
};

export default withRouter(Personal);
