const membersSchema = [
  {
    name: 'firstName',
    label: 'Name',
    type: 'text',
    showInList: true
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    showInList: true
  },
  {
    name: 'email',
    label: 'email',
    type: 'text',
    showInList: true
  }
];
export default membersSchema;
