import DirectoryService from 'components/app/directory/DirectoryService';
import defaultAvatar from 'assets/img/team/avatar.png';
import LeadService from 'components/app/leads/LeadService';
import DocumentService from 'components/app/documents/DocumentService';
import zipIcon from 'assets/img/icons/zip.png';
const SearchBoxService = {
  async initialItems() {
    let initialItems;
    let users;
    let leads;
    let docs;
    await DirectoryService.retrieveUsersByAccount().then(data => {
      users = data.map(d => ({
        id: d.identifier,
        url: '/social/directory/user-details/' + d.identifier,
        title: d.firstName + ' ' + d.lastName,
        catagories: 'suggestionMembers',
        icon: {
          img: d.avatar || defaultAvatar,
          size: 'l',
          status: 'status-online'
        }
      }));
      initialItems = users;
    });

    await LeadService.retrieveAllLeadByProfile().then(data => {
      leads = data.map(d => ({
        id: d.identifier,
        url: '/app/leads/' + d.identifier,
        title: d.firstName + ' ' + d.lastName,
        catagories: 'suggestionLeads',
        icon: {
          img: defaultAvatar,
          size: 'l'
        }
      }));
    });

    await DocumentService.retrieveDocumentsByProfile().then(data => {
      docs = data.map(d => ({
        id: d.identifier,
        title: d.name,
        catagories: 'suggestionFiles',
        url: d.img,
        file: true,
        img: zipIcon,
        imgAttrs: {
          class: 'border h-100 w-100 fit-cover rounded-lg'
        },
        time:
          '<span class="fw-semi-bold">' +
          d.profile.firstName +
          ' ' +
          d.profile.lastName +
          '</span><span class="fw-medium text-600 ms-2">' +
          d.dateCreated +
          '</span>'
      }));
    });

    return initialItems.concat(leads).concat(docs);
  }
};

export default SearchBoxService;
