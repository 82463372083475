import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useHeaderGenerator from 'components/app/common/functions/useHeaderGenerator';
import FormModal from 'components/app/common/FormModal';
import HolidayService from './HolidayService';
import SoftBadge from 'components/common/SoftBadge';
import schema from './jsonSchema';
import DirectoryService from '../directory/DirectoryService';
import DOMPurify from 'dompurify';

const formatDate = inputDate => {
  const date = new Date(inputDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

const HolidayDetails = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [holiday, setHoliday] = useState();
  const [headerGenerated, setHeaderGenerated] = useState();
  const [jsonSchema, setJsonSchema] = useState();
  const [status, setStatus] = useState();
  const sanitizeAndRenderHTML = htmlString => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    return { __html: sanitizedHTML };
  };
  const showValue = attributeName => {
    const value = holiday[attributeName];
    if (attributeName === 'start') {
      return formatDate(value);
    }
    if (attributeName === 'end') {
      return formatDate(value);
    }
    if (attributeName === 'comments') {
      return <div dangerouslySetInnerHTML={sanitizeAndRenderHTML(value)}></div>;
    }
    if (holiday[attributeName] === false) {
      return 'No';
    }
    if (holiday[attributeName] === true) {
      return 'Yes';
    }
    return value;
  };

  const retrieveButtons = holiday => {
    const { identifier } = DirectoryService.retrieveLoggedProfile();
    const holidayIdentifier = holiday.identifier;
    const acceptButton = {
      name: 'Approve',
      type: 'button',
      variant: 'success',
      onClick: () => {
        HolidayService.approveHoliday(holiday.identifier).then(() => {
          console.log(holiday.identifier);
          setStatus('APPROVED');
        });
      }
    };
    const rejectButton = {
      name: 'Reject',
      type: 'button',
      variant: 'alert',
      onClick: () => {
        HolidayService.rejectHoliday(holiday.identifier).then(() => {
          console.log(holiday.identifier);
          setStatus('REJECTED');
        });
      }
    };
    const editButton = {
      name: 'Edit Holiday',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Edit this holiday');
        navigate('/app/holidays/' + holidayIdentifier + '/edit');
      }
    };
    const deleteButton = {
      name: 'Archive request',
      type: 'button',
      variant: 'info',
      onClick: () => {
        console.log('Delete this holiday request');
        HolidayService.archiveHoliday(holidayIdentifier)
          .then(response => {
            setStatus(response.status), console.log(response);
          })
          .catch(error => console.log(error));
      }
    };

    const authorizedByIdentifier =
      holiday.authorizedBy !== undefined ? holiday.authorizedBy : null;
    /*const requestorIdentifier =
      holiday.profileIdentifier !== undefined
        ? holiday.profileIdentifier
        : holiday.profile.identifier;
    */
    const buttonsConf = [];
    if (
      authorizedByIdentifier === identifier &&
      holiday.status !== 'APPROVED'
    ) {
      buttonsConf.push(acceptButton);
    }
    if (
      authorizedByIdentifier === identifier &&
      holiday.status !== 'REJECTED'
    ) {
      buttonsConf.push(rejectButton);
    }
    if (
      authorizedByIdentifier === identifier ||
      holiday.profileIdentifier === identifier
    ) {
      buttonsConf.push(editButton);
    }
    if (holiday.profileIdentifier === identifier) {
      buttonsConf.push(deleteButton);
    }

    return buttonsConf;
  };
  useEffect(() => {
    const jsonSchema = schema;
    setJsonSchema(jsonSchema);
    HolidayService.retrieveHoliday(identifier)
      .then(data => {
        setHoliday(data),
          setStatus(data.status),
          setHeaderGenerated(
            useHeaderGenerator('Holiday', ' ', retrieveButtons(data))
          );
      })
      .catch(error => {
        console.error('Error retrieving Holiday', error);
      });
  }, []);

  return (
    <>
      {holiday && headerGenerated}
      {modalShow && (
        <FormModal
          message={'Holiday has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      {holiday && (
        <>
          <Card>
            <Card.Body>
              <Row key={'status'}>
                <Col xs={5} sm={4}>
                  <p className="fw-semi-bold mb-2 fs--1">{'Status' + ': '}</p>
                </Col>
                <Col>
                  <SoftBadge bg="success" className="me-2">
                    {status}
                  </SoftBadge>
                </Col>
              </Row>
              {jsonSchema.map(field => {
                if (field.type === 'group') {
                  return field.fields.map(f => (
                    <Row key={f.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-2 fs--1">
                          {f.label + ': '}
                        </p>
                      </Col>
                      <Col className="fs--1">{showValue(f.name)}</Col>
                    </Row>
                  ));
                } else {
                  return (
                    <Row key={field.name}>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-2 fs--1">
                          {field.label + ': '}
                        </p>
                      </Col>
                      <Col className="fs--1">{showValue(field.name)}</Col>
                    </Row>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default HolidayDetails;
