import React from 'react';
import { useNavigate } from 'react-router-dom';
import RolePositionList from './RolePositionList';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';

const RolePositionLandingPage = () => {
  const navigate = useNavigate();
  const buttonsConf = [
    {
      name: 'Create a new Job Vacancy',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Create a new Job Vacancy');
        navigate('/app/roles/role-form');
      }
    }
  ];
  const rolePositionLandingPageDescription =
    'Transform your hiring process with our efficient Recruitment module. Post job openings, track applications, and collaborate with your team to shortlist candidates all in one place. With powerful tools for candidate screening, interview scheduling, and communication, our module helps you find the right talent faster and more efficiently.';
  const headerGenerated = useHeaderGenerator(
    'Job Vacancies',
    rolePositionLandingPageDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      <RolePositionList />
    </>
  );
};

export default RolePositionLandingPage;
