import React, { useState, useEffect } from 'react';
import DocumentList from './DocumentsList';
import { Card } from 'react-bootstrap';
import SingleFile from './SingleFile';
import DirectoryService from '../directory/DirectoryService';
import defaultAvatar from 'assets/img/team/avatar.png';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
const Documents = () => {
  //const [activeUsers, setActiveUser] = useState([]);
  const [refreshDocuments, setRefreshDocuments] = useState(false);

  useEffect(() => {
    DirectoryService.retrieveFollowers().then(users => {
      const convertedUsers = users.map(u => ({
        id: u.identifier,
        name: u.firstName + ' ' + u.lastName,
        avatar: {
          status: 'online',
          src: u.avatar || defaultAvatar,
          size: '2xl'
        }
      }));
      console.log(convertedUsers);
      //setActiveUser(convertedUsers);
    });
  }, []);

  const buttonsConf = [];
  const documentPageDescription =
    'Securely manage all your HR documents in one centralized location with our Document Management module. Easily upload, organize, and share essential files such as contracts, policies, and employee records. With robust access controls, you can ensure that sensitive documents are only accessible to authorized personnel. This module streamlines document retrieval, supports version control, and ensures compliance with data management regulations, providing your HR team with a reliable and efficient way to handle all documentation needs.';
  const headerGenerated = useHeaderGenerator(
    'Documents Management',
    documentPageDescription,
    buttonsConf
  );
  //  const handleSharedWithUsers = userIdentifier => {
  //    let aux = sharedWithUsers;
  //    aux.push(userIdentifier);
  //setSharedWithUsers(aux);
  //  };

  return (
    <>
      {headerGenerated}
      <Card>
        <Card.Body>
          <SingleFile setRefreshDocuments={setRefreshDocuments}></SingleFile>
          <DocumentList
            setRefreshDocuments={setRefreshDocuments}
            refreshDocuments={refreshDocuments}
          ></DocumentList>
        </Card.Body>
      </Card>
    </>
  );
};

export default Documents;
