import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Button, Form, Modal, CloseButton } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
class InvoicePaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: this.props.showModal,
      date: null,
      contact: null,
      ref: null,
      amount: 0.0,
      identifier: null,
      success: false,
      errorMessage: null
    };
    this.setModalShow = this.setModalShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    let name = event.target.id;
    this.setState({ [name]: event.target.value });
  }
  handleSubmit(e) {
    e.preventDefault();
    const body = JSON.stringify({
      date: this.state.date,
      ref: this.state.ref,
      contact: this.state.contact,
      amount: this.state.amount
    });
    const url =
      'http://localhost:8095/invoice/' +
      this.props.invoiceIdentifier +
      '/payment';
    axios
      .post(url, body, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        this.setState({ identifier: response.data.identifier, success: true });
        this.setModalShow(false);
      })
      .catch(error => {
        this.setState({ errorMessage: error.message });
        console.error('There was an error!', error);
      });
  }
  setModalShow(show) {
    this.setState({ modalShow: show });
    if (show === false) {
      this.props.hideModal();
    }
  }
  render() {
    return (
      <>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Make a Payment
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => this.setModalShow(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit} id="payment-form">
              <Row className="mb-3">
                <Col>
                  <Form.Label htmlFor="date">Date</Form.Label>
                  <DatePicker
                    selected={this.state.date}
                    onChange={date => {
                      this.setState({ date: date });
                      console.log(date);
                    }}
                    className="form-control"
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </Row>
              <FloatingLabel
                controlId="contact"
                label="Contact"
                className="mb-3"
              >
                <Form.Control type="text" onChange={this.handleChange} />
              </FloatingLabel>
              <FloatingLabel controlId="ref" label="Ref Line" className="mb-3">
                <Form.Control type="text" onChange={this.handleChange} />
              </FloatingLabel>
              <FloatingLabel controlId="amount" label="Amount" className="mb-3">
                <Form.Control type="text" onChange={this.handleChange} />
              </FloatingLabel>

              <Button type="submit">Submit</Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

InvoicePaymentForm.propTypes = {
  hideModal: PropTypes.func,
  invoiceIdentifier: PropTypes.any,
  showModal: PropTypes.any
};

export default InvoicePaymentForm;
