import React, { useState, useEffect } from 'react';
import GoalService from './GoalService';
import { Card, Col, Button, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import filterFieldsForList from '../common/functions/filterFieldsForList';
import filterDataForList from '../common/functions/filterDataForList';
import schema from './goalSchema';
import AdvanceList from '../common/AdvanceList';

const GoalList = () => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState('');
  const [noDataToShow, setNoDataToShow] = useState(true);

  const convertTime = time => {
    const date = new Date(time);
    return date.toLocaleDateString();
  };

  useEffect(() => {
    const jsonSchema = schema;
    const fieldsForList = filterFieldsForList(jsonSchema);
    setColumns(fieldsForList);

    GoalService.retrieveAllGoalByAccount()
      .then(data => {
        const updatedData = { ...data, deadline: convertTime(data.deadline) };
        setData(
          filterDataForList(
            updatedData,
            columns.map(c => c.accessor)
          )
        );
        setNoDataToShow(!updatedData.length > 0);
      })
      .catch(error => setError({ error: error.message }));
  }, [noDataToShow]);
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="flex-between-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">Goal list</h5>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                variant="falcon-success"
                href="/social/directory/user-details"
              >
                Create a Goal
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      {error}
      {noDataToShow && (
        <Alert key="warning" variant="warning" className="fs--1">
          There's not any Goal to show. Let's create a new one!
        </Alert>
      )}
      {data && <AdvanceList columns={columns} data={data} />}
    </>
  );
};

export default GoalList;
