import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import cloudDownload from 'assets/img/icons/cloud-download.svg';
import { useState } from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import RoleService from './RolesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DirectoryService from '../directory/DirectoryService';
const InternalVacanciesWidget = ({ className }) => {
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState([]);
  useEffect(() => {
    RoleService.retrieveRolePositionsByUser().then(data => {
      if (data.length > 0) {
        const vacancies = data.reverse().slice(0, 3);
        setVacancies(vacancies);
      }
    });
  }, []);

  return (
    <Card className={className}>
      <FalconCardHeader
        title="Internal Vacancies"
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Link className="py-1 fs--1 font-sans-serif" to="/app/roles">
            View All
          </Link>
        }
      />
      {vacancies.length > 0 && (
        <Card.Body className="pb-0">
          {vacancies.map((v, index) => (
            <InternalVacancy
              key={v.identifier}
              vacancy={v}
              isLast={index === vacancies.length - 1}
            />
          ))}
        </Card.Body>
      )}
      {vacancies.length === 0 && (
        <Card.Body className="pb-0 text-center fs--1">
          <Alert variant="info" key={'info'}>
            There is not any internal vacancies at the moment
          </Alert>
          {DirectoryService.isLoggedByHRAdmin() && (
            <Button
              size="m"
              className="p-2 m-2 fs--1"
              onClick={() => navigate('/app/roles')}
            >
              Create a new job vacancy
            </Button>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

const InternalVacancy = ({ vacancy, isLast }) => {
  const { header, requestedBy, startDate, identifier, file } = vacancy;
  const img = file;
  return (
    <>
      <Flex alignItems="center" className="mb-3 hover-actions-trigger">
        <div className="file-thumbnail">
          <FontAwesomeIcon icon="file-pdf" className="text-success fs-3 me-3" />
        </div>
        <div className="ms-3 flex-shrink-1 flex-grow-1">
          <h6 className="mb-1">
            <Link
              className="stretched-link text-900 fw-semi-bold"
              to={'/app/roles/' + identifier}
            >
              {header}
            </Link>
          </h6>
          <div className="fs--1">
            <span className="fw-semi-bold">{'by ' + requestedBy.email}</span>
            <span className="fw-medium text-600 ms-2">{startDate}</span>
          </div>
          <div className="hover-actions end-0 top-50 translate-middle-y">
            <OverlayTrigger
              overlay={<Tooltip id="nextTooltip">Download</Tooltip>}
            >
              <Button
                variant="light"
                size="sm"
                className="border-300 me-1 text-600"
                as={'a'}
                href={img}
                download
              >
                <img src={cloudDownload} alt="Download" width={15} />
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </Flex>
      {!isLast && <hr className="bg-200" />}
    </>
  );
};

InternalVacancy.propTypes = {
  isLast: PropTypes.any,
  vacancy: PropTypes.shape({
    file: PropTypes.any,
    header: PropTypes.any,
    identifier: PropTypes.any,
    jobDescription: PropTypes.any,
    requestedBy: PropTypes.shape({
      email: PropTypes.any
    }),
    startDate: PropTypes.any
  })
};

InternalVacanciesWidget.propTypes = {
  className: PropTypes.string
};

export default InternalVacanciesWidget;
