import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../common/FormUtils';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../common/FormModal';
import GoalService from './GoalService';

import schema from './goalSchema';
const jsonSchema = schema;

const mergedJsonSchema = jsonSchema => {
  return jsonSchema.map(field => {
    if (field.type === 'group') {
      return {
        ...field,
        fields: field.fields.map(f => assignValueToJsonSchema(f))
      };
    } else {
      return assignValueToJsonSchema(field);
    }
  });
};

const assignValueToJsonSchema = field => {
  const progressValues = [
    { value: 0, label: '0%' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' }
  ];
  if (field.name === 'progress') {
    return {
      ...field,
      options: progressValues
    };
  }
  return field;
};
const GoalForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (undefined !== identifier) {
      GoalService.retrieveGoal(identifier)
        .then(data => {
          const updatedData = {
            ...data,
            progress: [{ label: data.progress + '%', value: data.progress }]
          };
          setData(updatedData);
        })
        .catch(error => console.log(error));
    }
  }, [identifier]);
  const handleGoalFormSubmit = formData => {
    GoalService.saveGoal(formData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Goal',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        console.log('Save Goal');
      }
    },
    {
      name: 'Save Draft',
      type: 'submit',
      variant: 'success',
      onClick: () => {
        console.log('Save Draft');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator('Create Goal', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Goal  has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleGoalFormSubmit}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({ data, buttonsConf, onSubmit }) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  onSubmit: PropTypes.any
};
export default GoalForm;
