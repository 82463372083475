import React from 'react';
import { Card, Row, Col, Dropdown } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import InvoiceForm from './InvoiceForm';
class InvoiceDetails extends React.Component {
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <Row className="flex-center">
              <Col>
                <Flex>
                  <div className="fs--1 ms-2 flex-1">
                    <h4 className="fs-0 text-capitalize">Invoice Details</h4>
                  </div>
                </Flex>
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Flex>
                  <Dropdown>
                    <Dropdown.Toggle variant="falcon-default"></Dropdown.Toggle>
                    <Dropdown.Menu className="py-2">
                      <Dropdown.Item href="#/action-1">
                        Create a new Recurring Invoice
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Create new Credit Note
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Flex>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <InvoiceForm />
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default InvoiceDetails;
