import React from 'react';
import { Card } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';
import { FloatingLabel } from 'react-bootstrap';
import DateInput from './DateInput';
import PropTypes from 'prop-types';
import DirectoryService from '../DirectoryService';
class Contract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier:
        this.props.contract !== null ? this.props.contract.identifier : null,
      lengthOfService: this.props.contract.lengthOfService,
      startDate: this.props.contract.startDate,
      contService: this.props.contract.contService,
      benefitsStart: this.props.contract.benefitsStart,
      jobType: this.props.contract.jobType,
      country: this.props.contract.country,
      location: this.props.contract.location,
      department: this.props.contract.department,
      myLineManager: this.props.contract.myLineManager,
      rightToWork: this.props.contract.rightToWork,
      rightToWorkExpires: this.props.contract.rightToWorkExpires,
      hoursPerWeek: this.props.contract.hoursPerWeek,
      daysPerWeek: this.props.contract.daysPerWeek,
      fte: this.props.contract.fte,
      workPattern: this.props.contract.workPattern,
      holidayEntitlement: this.props.contract.holidayEntitlement || 0,
      holidayBroughtForward: this.props.contract.holidayBroughtForward || 0,
      holidayTotalThisYear: this.props.contract.holidayTotalThisYear,
      noticePeriod: this.props.contract.noticePeriod,
      profileIdentifier: this.props.profile.identifier,
      validated: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(this.state);
  }
  handleChange(event) {
    let name = event.target.id;
    this.setState({ [name]: event.target.value });
  }
  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (form.checkValidity()) {
      const updatedContract = this.state;
      const profileIdentifier = this.state.profileIdentifier;
      console.log(updatedContract);
      this.props.updateContract(profileIdentifier, updatedContract);
    }
  }

  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <h5 className="fs-0 mb-3">
              Contract -{' '}
              {this.props.profile.firstName + ' ' + this.props.profile.lastName}{' '}
            </h5>
          </Card.Header>
          <Form
            className="mb-2"
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
          >
            <Card.Body>
              <Row className="g-2">
                <Col md>
                  <Card>
                    <FloatingLabel label="Length of service" className="mb-2">
                      <DateInput
                        id="lengthOfService"
                        value={this.state.lengthOfService}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Start date" className="mb-2">
                      <DateInput
                        id="startDate"
                        value={this.state.startDate}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Cont service" className="mb-2">
                      <DateInput
                        id="contService"
                        value={this.state.contService}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Benefits Start" className="mb-2">
                      <DateInput
                        id="benefitsStart"
                        value={this.state.benefitsStart}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Notice Period" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="noticePeriod"
                        type="text"
                        required
                        value={this.state.noticePeriod}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                  </Card>
                </Col>
                <Col md>
                  <Card>
                    <FloatingLabel label="Job Type" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="jobType"
                        type="text"
                        required
                        value={this.state.jobType}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Country" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="country"
                        type="text"
                        required
                        value={this.state.country}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Location" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="location"
                        type="text"
                        required
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Department" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="department"
                        type="text"
                        required
                        value={this.state.department}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="myLineManager" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="myLineManager"
                        type="text"
                        value={this.state.myLineManager}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Right To Work" className="mb-2">
                      <DateInput
                        id="rightToWork"
                        value={this.state.rightToWork}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      label="Right To Work Expires"
                      className="mb-2"
                    >
                      <DateInput
                        id="rightToWorkExpires"
                        value={this.state.rightToWorkExpires}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                  </Card>
                </Col>
              </Row>
              <Row className="g-2">
                <Col md>
                  <br />
                  <Card>
                    <FloatingLabel label="Hours per week" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="hoursPerWeek"
                        type="text"
                        required
                        value={this.state.hoursPerWeek}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Days per week" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="daysPerWeek"
                        type="text"
                        required
                        value={this.state.daysPerWeek}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="FTE" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="fte"
                        type="text"
                        required
                        value={this.state.fte}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Work Pattern" className="mb-2">
                      <Form.Control
                        size="sm"
                        id="workPattern"
                        type="text"
                        required
                        value={this.state.workPattern}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                  </Card>
                </Col>
                <Col md>
                  <br />
                  <Card>
                    <FloatingLabel label="Holiday Entitlement" className="mb-2">
                      <Form.Control
                        min="0"
                        size="sm"
                        id="holidayEntitlement"
                        type="text"
                        required
                        value={this.state.holidayEntitlement}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      label="Holiday Brought Forward"
                      className="mb-2"
                    >
                      <Form.Control
                        min="0"
                        size="sm"
                        id="holidayBroughtForward"
                        type="text"
                        required
                        value={this.state.holidayBroughtForward}
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      label="Holiday Total This Year"
                      className="mb-2"
                    >
                      <Form.Control
                        readonly
                        size="sm"
                        dis
                        id="holidayTotalThisYear"
                        type="text"
                        value={
                          +this.state.holidayBroughtForward +
                          +this.state.holidayEntitlement
                        }
                        onChange={this.handleChange}
                      />
                    </FloatingLabel>
                  </Card>
                </Col>
              </Row>
            </Card.Body>

            <Card.Footer>
              {DirectoryService.isLoggedByHRAdmin() && (
                <Button variant="info" className="me-2" type="submit">
                  Save Contract
                </Button>
              )}
            </Card.Footer>
          </Form>
        </Card>
      </>
    );
  }
}

Contract.propTypes = {
  contract: PropTypes.shape({
    benefitsStart: PropTypes.any,
    contService: PropTypes.any,
    country: PropTypes.any,
    daysPerWeek: PropTypes.any,
    department: PropTypes.any,
    fte: PropTypes.any,
    holidayBroughtForward: PropTypes.number,
    holidayEntitlement: PropTypes.number,
    holidayTotalThisYear: PropTypes.any,
    hoursPerWeek: PropTypes.any,
    identifier: PropTypes.any,
    jobType: PropTypes.any,
    lengthOfService: PropTypes.any,
    location: PropTypes.any,
    myLineManager: PropTypes.any,
    noticePeriod: PropTypes.any,
    rightToWork: PropTypes.any,
    rightToWorkExpires: PropTypes.any,
    startDate: PropTypes.any,
    workPattern: PropTypes.any
  }),
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    identifier: PropTypes.any,
    lastName: PropTypes.any
  }),
  updateContract: PropTypes.func
};

export default Contract;
