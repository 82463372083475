import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import people from 'data/people';
import ColleagueCard from './ColleagueCard';
import DirectoryService from './DirectoryService';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
//const followers = people.slice(0, 10);

const Directory = () => {
  const [followers, setFollowers] = useState([]);
  const directoryPageDescription =
    'Access a comprehensive and up-to-date directory of all employees with our Employee Directory page. Easily search for colleagues by name, department, or role, and view detailed profiles that include contact information, job titles, and organizational structure. This directory fosters collaboration by helping employees connect across teams and departments, promoting a sense of community within your organization. With a user-friendly interface, the Employee Directory makes it simple to find and reach out to the right people whenever needed.';
  const headerGenerated = useHeaderGenerator(
    'Employee Directory',
    directoryPageDescription,
    []
  );
  useEffect(() => {
    setFollowers(people.slice(0, 20));
    DirectoryService.retrieveUsersByAccount().then(data => {
      setFollowers(data);
    });
  }, []);

  return (
    <>
      {headerGenerated}
      {followers && (
        <Card>
          <Card.Body className="bg-light px-1 pb-1 pt-0 fs--1">
            <Row className="gx-0 gy-1 text-center">
              {followers.map(colleague => (
                <Col key={colleague.identifier} xs={6} md={4} lg={3} xxl={2}>
                  <ColleagueCard colleague={colleague} />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
export default Directory;
