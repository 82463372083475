import DirectoryService from '../directory/DirectoryService';
import { format } from 'date-fns';
const transformDateWithFormat = (britDate, formatStyle) => {
  const dateString = britDate;
  const date = new Date(dateString);
  const formattedDate = format(date, formatStyle);
  return formattedDate;
};
/*const transformDate = britDate => {
  const date = new Date(britDate);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getFullYear();

  // Format the date as dd/MM/yyyy
  const formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;
  return formattedDate;
};*/
const EventService = {
  saveManagementEvent: async function (event) {
    console.log(event);
    try {
      const profile = DirectoryService.retrieveLoggedProfile();
      const eventWithProfile = {
        ...event,
        profileIdentifier: profile.identifier,
        label: event.label.label
      };

      let url = '/api/event';
      let method = 'POST';
      console.log(event.identifier);
      if (eventWithProfile.identifier !== undefined) {
        method = 'PUT';
        url = '/api/event/' + eventWithProfile.identifier;
        eventWithProfile.attendeesValues = eventWithProfile.attendees;
        delete eventWithProfile.attendees;
        delete eventWithProfile.profile.children;
        delete eventWithProfile.profile;
      }
      console.log(eventWithProfile);
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventWithProfile)
      });

      if (response.ok) {
        let newEvent = await response.json();
        console.log(newEvent);
        const managerColour = 'primary';
        //const childrenColour = 'success';
        const managementEvent = {
          extendedProps: {
            status: newEvent.status,
            profile: newEvent.profile,
            identifier: newEvent.identifier,
            profileIdentifier: newEvent.profileIdentifier,
            editButtons: true
          },
          id: newEvent.identifier,
          title: newEvent.title,
          start: transformDateWithFormat(newEvent.start, 'yyyy-MM-dd'),
          startTime: transformDateWithFormat(newEvent.start, 'dd MMM, yyyy'),
          end: transformDateWithFormat(newEvent.end, 'yyyy-MM-dd'),
          endTime: transformDateWithFormat(newEvent.end, 'dd MMM, yyyy'),

          classNames: `border border-2 border-primary bg-100`,
          display: 'background',
          color: managerColour
        };

        console.log('Event has been created', managementEvent);

        return managementEvent; // Return the response from the server
      } else {
        throw new Error('Error saving Event');
      }
    } catch (error) {
      console.log('Error saving Event', error);
      throw error; // Throw the error to handle it outside the function if needed
    }
  },
  retrieveManagementEventByIdentfier: async function (identifier) {
    try {
      const response = await fetch('/api/event/' + identifier);
      const data = await response.json();
      return data;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveManagementEventsForHome: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/event/profile/' + profile.identifier);
      const managerColour = 'primary';
      const childrenColour = 'success';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          attendees: h.attendees,
          url: h.url,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title: h.title,
        start: transformDateWithFormat(h.start, 'yyyy-MM-dd'),
        startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
        end: transformDateWithFormat(h.end, 'yyyy-MM-dd'),
        endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),

        classNames: `border border-2 border-primary bg-100`,
        display: 'background',
        color:
          h.authorizedBy === profile.identifier ? childrenColour : managerColour
      }));

      /*if(localStorage.getItem('bstDemoMode')==='true'){
        const eventsWithDemoHolidays = events || [];
        return eventsWithDemoHolidays.concat(DemoService.retrieveDemoEvents());
      }*/

      console.log(events);
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveManagementEvents: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/event/profile/' + profile.identifier);
      const managerColour = 'primary';
      const childrenColour = 'success';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          attendees: h.attendees,
          url: h.url,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title: h.title,
        start: transformDateWithFormat(h.start, 'yyyy-MM-dd'),
        startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
        end: transformDateWithFormat(h.end, 'yyyy-MM-dd'),
        endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),
        color:
          h.authorizedBy === profile.identifier ? childrenColour : managerColour
      }));

      /*if(localStorage.getItem('bstDemoMode')==='true'){
        const eventsWithDemoHolidays = events || [];
        return eventsWithDemoHolidays.concat(DemoService.retrieveDemoEvents());
      }*/

      console.log(events);
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  }
};
export default EventService;
