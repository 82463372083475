import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import useFormGenerator from '../../common/FormUtils';
import useHeaderGenerator from '../../common/functions/useHeaderGenerator';
import useButtonsGenerator from '../../common/functions/useButtonsGenerator';
import { useParams } from 'react-router-dom';
import FormModal from '../../common/FormModal';
import EventService from '../EventService';
import DirectoryService from 'components/app/directory/DirectoryService';
import schema from './jsonSchema';
const jsonSchema = schema;

const mergedJsonSchema = (jsonSchema, users, labels) => {
  const attendees = users;
  return jsonSchema.map(field => {
    if (field.name === 'attendees') {
      return {
        ...field,
        options: attendees
      };
    }
    if (field.name === 'label') {
      return {
        ...field,
        options: labels
      };
    }
    return field;
  });
};

const ManagementEventForm = () => {
  const { identifier } = useParams();
  const [data, setData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const formRef = useRef(null);
  /*const handleChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' && target.value === 'on' ? true : false;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };*/
  useEffect(async () => {
    try {
      if (identifier) {
        EventService.retrieveManagementEventByIdentfier(identifier)
          .then(data => {
            const attendees = data.attendees.map(u => ({
              label: u.firstName + ' ' + u.lastName,
              value: u.identifier
            }));
            const label = [{ label: data.label, value: data.label }];
            const allDay = data.allDay === true ? 'on' : 'off';
            const updatedData = {
              ...data,
              label: label,
              attendees: attendees,
              allDay: allDay
            };

            setData(updatedData);
          })
          .catch(error => console.log(error));
      }
      const usersByAccount = await DirectoryService.retrieveUsersByAccount();
      const mappedUsers = usersByAccount.map(u => ({
        value: u.identifier,
        label: u.firstName + ' ' + u.lastName
      }));
      setUsers(mappedUsers);
      const labels = [
        {
          label: 'Business',
          value: 'Business'
        },
        {
          label: 'Important',
          value: 'Important'
        },
        {
          label: 'Personal',
          value: 'Personal'
        },
        {
          label: 'Must Attend',
          value: 'Must Attend'
        }
      ];
      setLabels(labels);
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);
  const handleManagementEventFormSubmit = formData => {
    const allDay = formData.allDay === 'on' ? true : false;
    const udpatedFormData = {
      ...formData,
      allDay: allDay
    };
    EventService.saveManagementEvent(udpatedFormData)
      .then(response => {
        console.log(response);
        setModalShow(true);
      })
      .catch(error => console.log(error));
  };

  const buttonsConf = [
    {
      name: 'Save Management Event',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        if (formRef.current) {
          formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          );
        }
        console.log('Save ManagementEvent');
      }
    }
  ];
  const headerGenerated = useHeaderGenerator('Event', ' ', buttonsConf);

  return (
    <>
      {headerGenerated}
      {modalShow && (
        <FormModal
          message={'Event has been updated!'}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
      <Card>
        <Card.Body>
          {data || undefined === identifier ? (
            // Pass data to the form generator hook
            <FormGeneratorWithData
              data={data}
              buttonsConf={buttonsConf}
              onSubmit={handleManagementEventFormSubmit}
              users={users}
              labels={labels}
              formRef={formRef}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
const FormGeneratorWithData = ({
  data,
  buttonsConf,
  onSubmit,
  users,
  labels,
  formRef
}) => {
  const [validated, setValidated] = useState(false);
  const buttonsGenerated = useButtonsGenerator(buttonsConf);
  // Use the useFormGenerator hook with the provided data
  const { formFields, handleSubmit } = useFormGenerator(
    mergedJsonSchema(jsonSchema, users, labels),
    onSubmit,
    data,
    setValidated
  );

  // Render your form using the formFields and event handlers
  return (
    <Form
      className="mb-2"
      ref={formRef} // This line assigns the form element to the formRef
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {formFields}
      <Card.Footer>{buttonsGenerated}</Card.Footer>
    </Form>
  );
};

FormGeneratorWithData.propTypes = {
  buttonsConf: PropTypes.any,
  data: PropTypes.any,
  formRef: PropTypes.any,
  labels: PropTypes.any,
  onSubmit: PropTypes.any,
  users: PropTypes.any
};
export default ManagementEventForm;
