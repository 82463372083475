import EventService from '../management-events/EventService';
import HolidayService from '../holidays/HolidayService';

const CalendarService = {
  retrieveEntriesByProfile: async function () {
    try {
      const events = await EventService.retrieveManagementEvents();
      const holidayRequests = await HolidayService.retrieveHolidayRequests();

      // Merge the two arrays using the spread operator
      const mergedEvents = [...events, ...holidayRequests];

      //mergedEvents.sort((a, b) => new Date(a.start) - new Date(b.start));
      console.log(mergedEvents);

      return mergedEvents;
    } catch (error) {
      console.error('There was an error!', error);
      throw error;
    }
  }
};

export default CalendarService;
