import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Greetings from '../../dashboards/project-management/Greetings';

import { greetingItems } from 'data/dashboard/projectManagement';

const CustomLandingPage = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          <Greetings data={greetingItems} />
        </Col>
        <Col xxl={3} md={6}></Col>
        <Col xxl={3} md={6}></Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col xxl={6} lg={12}>
          <Row>
            <Col lg={12}></Col>
            <Col lg={12}></Col>
          </Row>
        </Col>
        <Col xxl={{ span: 6, order: 1 }} lg={6}></Col>
        <Col xxl={6} lg={6}></Col>
        <Col xxl={{ span: 6, order: 1 }} lg={6}></Col>
        <Col xxl={{ span: 4, order: 3 }} lg={6}></Col>
        <Col xxl={{ span: 8, order: 2 }}></Col>
        <Col xxl={{ span: 12, order: 3 }}></Col>
      </Row>

      <Row className="g-3">
        <Col xxl={8}></Col>
        <Col xxl={4}></Col>
      </Row>
    </>
  );
};

export default CustomLandingPage;
