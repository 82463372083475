const InvoiceService = {
  retrieveAllPaymentsForInvoice: async function (invoiceIdentifier) {
    const url =
      'http://localhost:8095/invoice/' + invoiceIdentifier + '/payments';
    const payments = await fetch(url)
      .then(response => {
        return response.json();
      })
      .then(payments => {
        console.log(payments);
        return payments;
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return payments;
  },
  retrieveAllInvoices: async function () {
    const accountIdentifier = '9c72efcc-fe0b-11ec-b939-0242ac120002'; //TODO
    const url = 'http://localhost:8095/invoice/account/' + accountIdentifier;
    const invoices = await fetch(url)
      .then(response => {
        return response.json();
      })
      .then(invoices => {
        console.log(invoices);
        return invoices;
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return invoices;
  },
  retrieveInvoice: async function (identifier) {
    const url = 'http://localhost:8095/invoice/' + identifier;
    const invoice = await fetch(url)
      .then(response => {
        return response.json();
      })
      .then(invoice => {
        console.log(invoice);
        return invoice;
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
    return invoice;
  }
};
export default InvoiceService;
