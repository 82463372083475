import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import ExpensesList from './ExpenseList';
import { Tabs, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import { accountancyStats } from 'data/dashboard/saas';
class ExpensesLandingPage extends React.Component {
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            <Row className="flex-center">
              <Col>
                <Flex>
                  <div className="fs--1 ms-2 flex-1">
                    <h4 className="fs-0 text-capitalize">Expenses</h4>
                  </div>
                </Flex>
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Flex>
                  <Button
                    variant="falcon-success"
                    size="sm"
                    className="px-4 px-sm-5"
                  >
                    Create Expense
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row className="g-3 mb-3">
              <Col md={6} xxl={3}>
                {accountancyStats.slice(0, 1).map((stat, index) => (
                  <StatisticsCard
                    key={index}
                    stat={stat}
                    style={{ minHeight: '10rem', minWidth: '12rem' }}
                  />
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br></br>
        <Card>
          <Card.Body>
            <Tabs defaultActiveKey="all" id="controlled-tab-example">
              <Tab
                eventKey="all"
                title="All"
                className="border-bottom border-x p-3"
              >
                <ExpensesList></ExpensesList>
              </Tab>
              <Tab
                eventKey="readyToClaim"
                title="Ready To Claim"
                className="border-bottom border-x p-3"
              >
                <ExpensesList></ExpensesList>
              </Tab>
              <Tab
                eventKey="paid"
                title="Paid"
                className="border-bottom border-x p-3"
              >
                <ExpensesList></ExpensesList>
              </Tab>
              <Tab
                eventKey="receiptScan"
                title="Receipt Scan"
                className="border-bottom border-x p-3"
              >
                <ExpensesList></ExpensesList>
              </Tab>
              <Tab
                eventKey="recurring"
                title="Recurring"
                className="border-bottom border-x p-3"
              >
                <ExpensesList></ExpensesList>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ExpensesLandingPage;
