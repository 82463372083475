const EquipmentService = {
  async saveEquipment(formData) {
    try {
      let url = '/api/<your_controller>';
      let method = 'PUT';
      if (formData.identifier === undefined) {
        const profileLogged = JSON.parse(localStorage.getItem('profile'));
        const { accountIdentifier } = profileLogged;
        console.log(profileLogged);
        console.log(accountIdentifier);
        url = '/api/<your_controller>';
        method = 'POST';
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        const newEquipment = await response.json();
        console.log('Equipment has been updated:', newEquipment);
      }
    } catch (error) {
      console.log('Error saving Equipment', error);
    }
  },
  async retrieveAllEquipmentByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch(
        '/api/<your_controller>' + accountIdentifier
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Equipment', error);
      throw error;
    }
  },
  async retrieveEquipment(identifier) {
    try {
      const response = await fetch('/api/<your_controller>/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Equipment', error);
      throw error;
    }
  },
  async deleteEquipment(identifier) {
    //TODO finisih this method.
    try {
      let url = '/api/<your_controller>' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newEquipment = await response.json();
        console.log('Equipment has been deleted:', newEquipment);
      }
    } catch (error) {
      console.log('Error deleting Equipment', error);
    }
  }
};
export default EquipmentService;
