const jsonSchema = [
  {
    name: 'title',
    label: 'Title',
    type: 'text',
    required: true
  },
  {
    type: 'group',
    fields: [
      {
        name: 'start',
        label: 'Start Date',
        type: 'daypicker',
        required: true,
        pattern: 'dd/MM/yyyy h:mm aa'
      },
      {
        name: 'end',
        label: 'End Date',
        type: 'daypicker',
        required: true,
        pattern: 'dd/MM/yyyy h:mm aa'
      }
    ]
  },
  {
    name: 'attendees',
    label: 'Attendees',
    type: 'multiselect',
    showInList: true,
    isMulti: true,
    required: true
  },
  {
    name: 'allDay',
    label: 'All Day',
    type: 'checkbox'
  },
  {
    name: 'url',
    label: 'Schedule Meeting Url',
    type: 'text'
  },
  {
    name: 'description',
    label: 'Description',
    type: 'editor'
  },
  {
    name: 'label',
    label: 'Label',
    type: 'multiselect',
    showInList: true,
    isMulti: false,
    required: true
  }
];

export default jsonSchema;
